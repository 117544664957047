<template>
  <div class="row">
    <div class="col-lg-12">
          <div
                :class="[
                    'card',
                    'card-border-color',
                    'card-border-color-primary',
                    ' card-table',
                    'be-loading',
                    { 'be-loading-active': isBusy },
                ]"
            >
						<div class="card-header">
              <h3>Create Broadcast Message</h3>
						</div>
						<div class="card-body">
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label"
                  >Broadcast Title*</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid': v1.title }]"
                    type="text"
                    v-model="content.title"
                  />
                  <ul v-if="v1.name" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label"
                  >Broadcast Message*</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <textarea
                    :class="['form-control', { 'is-invalid': v1.message }]"
                    rows="6"
                    maxlength="1000"
                    v-model="content.message"
                  ></textarea>
                  <span>{{1000 - content.message.length}} character(s) remaining.</span>
                  <ul v-if="v1.message" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>

               <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label"
                  >Attach Image</label
                >
                <div class="col-12 col-sm-9">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <input type="file" ref="file" accept="image/*" @change="previewFiles" style="display:none">
                            <button @click.prevent="$refs.file.click()" class="btn btn-space btn-primary" >Upload Image</button>
                            <ul v-if="v1.name" class="parsley-errors-list filled">
                                <li class="parsley-required">Required.</li>
                            </ul>
                        </div>
                        <div v-if="attachedImageUrl" class="col-12 col-sm-8">
                            <img alt="business logo"  height="180px" width="240px" class="logoImage" :src="`${attachedImageUrl}`"/>
                            <i class="feather icon-x text-danger" @click.prevent="removeAttachedImage" style="font-weight:bold; font-size: 18px; position: absolute; top: -5px; cursor: pointer"></i>
                        </div>
                    </div>
                </div>
              </div>

              <div v-if="attachedImageUrl" class="form-group row">
                <label class="col-12 col-sm-3 col-form-label mt-1"
                  >Image position*</label
                >
                <div class="col-12 col-sm-8 col-lg-6 d-flex flex-row">
                  <div class="mr-sm-2 mr-md-5 mt-1">
                    <label class="radioLabel" for="top">Top</label>
                    <input 
                      type="radio" 
                      class="radioInput mt-1"
                      id="top" 
                      name="imagePosition"
                      v-model="content.imagePosition" 
                      value="top"
                    >
                  </div>
                  <div class="mt-1">
                    <label class="radioLabel" for="bottom">Bottom</label>
                    <input type="radio" class="radioInput mt-1" id="bottom" name="imagePosition" v-model="content.imagePosition"  value="bottom">

                  </div>
                  <ul v-if="v1.imagePosition" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label"
                  >Info Url</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid': v1.infoUrl }]"
                    type="text"
                    v-model="content.infoUrl"
                  />
                  <ul v-if="v1.infoUrl" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>

            </div>
            <div class="card-footer">
            </div>
          </div>

           <div
                :class="[
                    'card',
                    'card-border-color',
                    'card-border-color-primary',
                    ' card-table',
                    'be-loading',
                    { 'be-loading-active': isBusy },
                ]"
            >
			    <div class="card-header">
                     <h3>Send To</h3>
				</div>
				<div class="card-body">
                    <p> Select Your Recipients </p>
                    <form>
                        <div class="d-flex mb-2">
                        <div class="mr-sm-2 mr-md-5">
                            <label class="radioLabel" for="dist">Distributors only</label>
                            <input 
                            type="radio" 
                            class="radioInput"
                            id="dist" 
                            name="usertype"
                            v-model="content.userType" 
                            value="0"
                            >
                        </div>
                        <div>
                            <label class="radioLabel" for="merc">Merchants only</label>
                            <input type="radio" class="radioInput" id="merc" name="usertype" v-model="content.userType"  value="1">

                        </div>
                        </div>
                    
                        <label class="radioLabel" for="both">Both</label>
                        <input type="radio" class="radioInput" id="both" name="usertype" v-model="content.userType" value="2">
                    </form>
                </div>
                <div class="card-footer">
                </div>
          </div>

          <div class="my-3 d-flex justify-content-between flex-row">
            <button @click.prevent="openBroadcastPreview" class="btn btn-space" style="background-color: #134d84; color: white">
				Preview
			</button>

            <div>
                <button  @click.prevent="openCancelCaution" class="btn btn-text btn-space mr-1">
                    <span class="text-danger"> Cancel </span>
                </button>
                <div class="bg-primary d-inline w-100 p-1">
                    <button @click.prevent="sendBroadcast(1)" class="btn btn-primary btn-space" style="border-right: 1px solid black">
                        Send
                    </button>
                    <button
                        class="btn btn-primary btn-md dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                    </button>
                    <div
                    class="dropdown-menu px-1"
                    aria-labelledby="dropdownMenu2"
                    >
                        <a
                            class="dropdown-item p-1"
                            @click.prevent="openSchedule"
                            href="#"
                        >
                            Broadcast Later
                        </a>
                        <a
                            class="dropdown-item p-1"
                            @click.prevent="sendBroadcast(0)"
                            href="#"
                        >
                            Save as Draft
                        </a>
                    </div>
                </div>
               
            </div>

          </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-schedule" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
                <div>
                    <h4 class="modal-title"><b>Schedule Broadcast</b></h4>
                    <span>You can only schedule 90days ahead</span>
                </div>
                <button
                    class="close md-close"
                    type="button"
                    data-dismiss="modal"
                    aria-hidden="true"
                    @click.prevent="closeSchedule"
                >
                    <span class="mdi mdi-close"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row mt-2">
                    <label class="col-12 col-sm-3 col-form-label"
                    >Select Date*</label
                    >
                    <div class="col-12 col-sm-8">
                        <input
                            :class="['form-control', {'is-invalid': isScheduleInvalid}]"
                            type="date"
                            :min="minDate"
                            :max="maxDate"
                            placeholder="Select a date"
                            v-model="scheduleDate"
                        />
                        <ul v-if="isScheduleInvalid" class="parsley-errors-list filled mt-1">
                            <li class="parsley-required">You must select a valid date.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
              <button
                style="width: 100px;"
                class="btn btn-text btn-space"
                type="button"
                data-dismiss="modal"
                @click.prevent="closeSchedule"
              >
                <span class="text-danger"> cancel </span>
              </button>

               <button
                style="width: 100px;"
                class="btn btn-primary"
                type="button"
                :disabled="isBusy"
                @click.prevent="sendBroadcast(2)"
              >
                <span v-if="!isBusy">Schedule</span>
                <span v-else>Busy...</span>
              </button>

            </div>
          </div>
        </div>
    </div>

      <div class="modal fade colored-header colored-header-primary" id="mod-cancel" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content p-2">
            <div class="modal-body">
               <p class="text-center">Do you want to stop sending this message?</p>
            </div>

            <div class="mt-2 d-flex flex-row justify-content-between">
              <button
                style="width: 100px;"
                class="btn btn-text btn-space"
                type="button"
                data-dismiss="modal"
              >
                <span class="text-danger"> No, I don't </span>
              </button>

               <button
                style="width: 100px;"
                class="btn btn-text btn-space"
                type="button"
                @click.prevent="cancelBroadcast"
              >
                <span>Yes, I do</span>
              </button>

            </div>
          </div>
        </div>
    </div>

    <BroadcastPreview @closeModal="closeBroadcastPreview" :details="previewDetails"></BroadcastPreview>

  </div>
</template>


<script>
/* eslint-disable */

import { helper } from "@/helper";
import moment from "moment";
import Pagination from "../../components/Pagination.vue";
import BroadcastPreview from "../../components/Broadcast/BroadcastPreview.vue";

export default {
  name: "Enterprise-Broadcast",
  components: {
    Pagination,
    BroadcastPreview
  },

  mounted: function() {
    this.minDate = helper.preventFutureDateSelection();
    let calMaxDate = moment(this.minDate).add(90, 'days').toDate();
    this.maxDate = moment(calMaxDate).format("YYYY-MM-DD")

    let messageId = this.$route.params.id;
    if(messageId){
        this.fetchBroadcastById(messageId);
        this.isUpdating = true;
    }

  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      isUpdating: false,
      minDate: "",
      maxDate: "",
      content: {
        id: "",
        title: "",
        message: "",
        imagePosition: "",
        infoUrl: "",
        userType: ""
      },
      v1: {
        title: false,
        message: false,
        userType: false,
        imagePosition: false,
        infoUrl: false
      },
      attachedImage: null,
      attachedImageUrl: "",
      scheduleDate: "",
      isScheduleInvalid: false,
      previewDetails: {
        title: "",
        sender: "",
        date: "",
        message: "",
        imagePosition: "",
        infoUrl: "",
        imageAttach: "",
      }
    }
  },

  computed: {
        user: function() {
            return this.$store.state.currentUser;
        },
    },

  methods: {
    
    validateContent(){
      this.v1 = {
        title: false,
        message: false,
        userType: false,
        imagePosition: false,
        infoUrl: false
      }

      var isValid = true;

      if(!this.content.title){
        this.v1.title = true;
        isValid = false;
      }

      if(!this.content.message){
        this.v1.message = true;
        isValid = false;
      }

      if(!this.content.imagePosition && this.attachedImage){
          this.v1.imagePosition = true
          isValid = false;
      }

      return isValid;
    },

    openCancelCaution(){
        $("#mod-cancel").modal("show");
    },

    cancelBroadcast(){
        this.content =  {
            title: "",
            message: "",
            imagePosition: "",
            infoUrl: "",
            userType: ""
        };
        $("#mod-cancel").modal("hide");
        this.$router.push("/manufacturer-broadcast")
    },

    openSchedule(){
        $("#mod-schedule").modal("show");
    },

    closeSchedule(){
        $("#mod-schedule").modal("hide");
        this.scheduleDate = "";
        this.isScheduleInvalid = false;
    },

    openBroadcastPreview(){
        this.previewDetails = {
            title: this.content.title,
            message: this.content.message,
            sender: this.user.parent.name,
            date: this.scheduleDate ? moment(this.scheduleDate ).format("dddd, MMM DD, hh:mma"): moment(new Date()).format("dddd, MMM DD, hh:mma"),
            imagePosition: this.content.imagePosition,
            infoUrl: this.content.infoUrl,
            imageAttach: this.attachedImageUrl,
        }
        $("#mod-read-broadcast").modal("show");
    },

    closeBroadcastPreview(){
        $("#mod-read-broadcast").modal("hide");
    },

    removeAttachedImage(){
      this.attachedImage = null;
      this.attachedImageUrl = "";
    },

    previewFiles(event) {
      event.preventDefault();
      let file = event.target.files[0];
      this.attachedImage = event.target.files[0];
      this.attachedImageUrl = URL.createObjectURL(file);
    },

    
    async fetchBroadcastById(id){
        this.isBusy = true;

        await this.$http
          .get(`/BroadcastMessages/${id}`)
          .then(function (res) {
            this.content =  {
                id: res.body.id,
                title: res.body.title,
                message: res.body.message,
                imagePosition: res.body.imagePosition,
                infoUrl: res.body.link,
                userType: res.body.recipentType
            };
            this.scheduleDate = moment(res.body.broadCastTime).format("MM/DD/YYYY");
            this.attachedImageUrl = res.body.imageUrl;
            this.isBusy = false;
          })
          .catch(function () {
            this.isBusy = false;
          });
    },

    sendBroadcast(type){
      this.isBusy = true;

      if(this.validateContent()){
        if(type === 2 && !this.scheduleDate){
            return this.isScheduleInvalid = true;
        } 

        if(!this.isUpdating){
            let payload = {
                Title: this.content.title,
                Message: this.content.message,
                Status: type,
                RecipentType: this.content.userType,
                ScheduledTime: this.scheduleDate,
                Image: this.attachedImage,
                ImagePosition: this.content.imagePosition,
                Link: this.content.infoUrl
            }

            const formData = new FormData();
                for (const [key, value] of Object.entries(payload)) {
                    formData.append(`${key}`, value);
            }

            this.$http
            .post("/BroadcastMessages", formData)
            .then(function (res) {
                this.$toast.success(res.body, {
                icon: true,
                rtl: false,
                });
                this.content =  {
                    id: "",
                    title: "",
                    message: "",
                    imagePosition: "",
                    infoUrl: "",
                    userType: ""
                };
                this.closeSchedule();
                // this.fetchSentMessages();
                this.isBusy = false;
                this.$router.push("/manufacturer-broadcast");
            })
            .catch(function () {
                this.isBusy = false;
            });
        }else{
            let payload = {
                Id: this.content.id,
                Title: this.content.title,
                Message: this.content.message,
                Status: type,
                RecipentType: this.content.userType,
                ScheduledTime: this.scheduleDate,
                Image: this.attachedImage,
                DeleteImage: this.attachedImageUrl ? false : true,
                ImagePosition: this.content.imagePosition,
                Link: this.content.infoUrl
            }

            const formData = new FormData();
                for (const [key, value] of Object.entries(payload)) {
                    formData.append(`${key}`, value);
            }

            this.$http
            .put(`/BroadcastMessages/update/${this.content.id}`, formData)
            .then(function (res) {
                this.$toast.success(res.body, {
                icon: true,
                rtl: false,
                });
                this.content =  {
                    id: "",
                    title: "",
                    message: "",
                    imagePosition: "",
                    infoUrl: "",
                    userType: ""
                };
                this.closeSchedule();
                // this.fetchSentMessages();
                this.isBusy = false;
                this.$router.push("/manufacturer-broadcast");
            })
            .catch(function () {
                this.isBusy = false;
            });
        }
        
      }

    },

  }
};
</script>


<style scoped>
    .radioLabel{
    font-size: 16px;
    margin-right: 20px;
  }

  .radioInput{
    width: 18px;
    height: 18px;
  }
</style>
