<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add-distributor"
    role="dialog"
  >
    <div class="modal-dialog">
      <form class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Add Distributors To Group</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="reset"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <multiselect
            v-model="value"
            :multiple="true"
            label="text"
            track-by="text"
            :preserve-search="true"
            :options="distributors"
          ></multiselect>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-danger"
            type="button"
            data-dismiss="modal"
            @click="reset"
          >
            Cancel
          </button>
          <button
            :disabled="isBusyModal"
            class="btn btn-primary"
            @click.prevent="submit"
            type="submit"
          >
            <span v-if="isBusyModal">Busy...</span>
            <span v-else>Add Distributors</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Multiselect from "vue-multiselect";
export default {
  name: "AddGroupDistributor",

  components: { Multiselect },

  props: ["groupId"],

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      value: "",
      distributors: [],
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  mounted() {
    this.getDistributors();
  },

  methods: {
    async getDistributors() {
      this.isBusy = true;
      this.isBusyModal = true;

      try {
        const response = await this.$http.get(
          "/Accounts/getItemList?parentId=" +
            this.user.parentId +
            "&accountType=Distributor"
        );

        if (response.ok) this.distributors = response.body;

        this.isBusy = false;
        this.isBusyModal = false;
      } catch (error) {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    async submit() {
      this.isBusy = true;
      this.isBusyModal = true;
      try {
        const distributors = this.value.map((val) => {
          return { id: val.id, name: val.text };
        });

        const data = {
          type: "Distributor",
          groupId: this.groupId,
          members: distributors,
        };

        const response = await this.$http.post("/Groups/addMembers", data);
        if (response.ok || response.status === 400) {
          this.reset();
          this.$emit("onComplete");
        }

        this.isBusy = false;
        this.isBusyModal = false;
      } catch (error) {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    reset() {
      this.value = "";
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>