<template>
  <button
    data-toggle="tooltip"
    title="Edit"
    :class="[{ 'btn-success': stage.active }, { 'btn-danger': !stage.active }]"
    class="btn btn-sm btn-rounded btn-space mr-1"
    :disabled="activeLoading"
    @click.prevent="switchStatus"
  >
    <span
      v-if="activeLoading"
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <i class="sr-only">Loading...</i>
    </span>

    <span v-else>
      {{ stage.active ? "Active" : "Inactive" }}
    </span>
  </button>
</template>

<script>
export default {
  name: "SwitchStageStatus",
  props: ["stage"],

  data() {
    return {
      activeLoading: false,
    };
  },

  methods: {
    async switchStatus() {
      this.activeLoading = true;

      try {
        const response = await this.$http.put(
          "/PurchaseOrders/orderStage/update/" +
            this.stage.id +
            "/" +
            !this.stage.active
        );

        if (response.ok && response.status === 200) this.$emit("onComplete");
        this.activeLoading = false;
      } catch (error) {
        this.activeLoading = false;
      }
    },
  },
};
</script>
