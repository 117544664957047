<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button
            class="btn btn-secondary btn-rounded btn-space"
            @click="viewFilter"
          >
            <span class="feather icon-filter text-white"></span> Filters
          </button>
          <button
            v-if="hasPermission('Export_Distributor_Data')"
            class="btn btn-success"
            @click="downloadAll"
          >
            <span
              v-if="exportAllLoading"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else>
              <i class="icon icon-left mdi mdi-arrow-top-right" />
              Export
            </span>
          </button>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 20%">Name</th>
                      <th>Code</th>
                      <th>Source ID</th>
                      <th>State</th>
                      <th>Group</th>
                      <th>Status</th>
                      <th>Created</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="m in distributors" :key="m.id">
                      <td>
                        <a href="#" @click.prevent="details(m.id)">
                          <strong>{{ m.name }}</strong>
                        </a>
                        <br />
                        <span>{{ m.description }}</span>
                      </td>
                      <td>{{ m.code }}</td>
                      <td>{{ m.sourceId }}</td>
                      <td>{{ m.state }}</td>
                      <td>{{ m.group ? m.group.name : "" }}</td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': m.active },
                            { 'badge-danger': !m.active },
                          ]"
                        >
                          {{ m.active ? "Active" : "Inactive" }}
                        </span>
                      </td>
                      <td>{{ formatDate(m.created) }}</td>
                      <td>
                        <router-link
                          v-if="hasPermission('Group_View')"
                          class="
                            btn btn-primary btn-sm btn-rounded btn-space
                            mr-1
                          "
                          :to="{
                            path: `manufacturer-distributors/${m.id}/manage-group`,
                          }"
                        >
                          <span>Manage Group</span>
                        </router-link>
                      </td>
                      <td>
                        <button
                          v-if="
                            hasPermission(
                              'Distributor_Product_Permission_View'
                            ) ||
                              hasPermission(
                                'Distributor_Product_Permission_Update'
                              )
                          "
                          class="btn btn-outline-primary btn-sm dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          More Actions
                        </button>
                        <div
                          class="dropdown-menu px-1"
                          aria-labelledby="dropdownMenu2"
                        >
                          <a
                            v-if="
                              hasPermission(
                                'Distributor_Product_Permission_View'
                              )
                            "
                            class="dropdown-item"
                            href="#"
                            @click.prevent="viewDistributorProductPermission(m)"
                          >
                            View Product Permissions
                          </a>
                          <div
                            v-if="
                              hasPermission(
                                'Distributor_Product_Permission_Update'
                              )
                            "
                            class="dropdown-divider"
                          ></div>
                          <a
                            v-if="
                              hasPermission(
                                'Distributor_Product_Permission_Update'
                              )
                            "
                            class="dropdown-item"
                            href="#"
                            @click.prevent="
                              viewDistributorProductPermissionForUpdate(m)
                            "
                          >
                            Update Product Permissions
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!distributors.length">
                      <td colspan="9">
                        <div class="text-center" style="padding-top: 25px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-truck-fast"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 50px">
                          You don't have any distributors.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} distributor{{ count != 1 ? "s" : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-export"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Export</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button style="width: 100px" class="btn btn-primary" type="button">
              Export
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option value="">All Distributors</option>
                  <option value="True">Active</option>
                  <option value="False">Disabled</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by name..."
                  v-model="filter.name"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Code</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by code..."
                  v-model="filter.code"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Source Id</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by source id..."
                  v-model="filter.sourceId"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Group</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.group"
                >
                  <option v-for="g in groups" :key="g.id" :value="g.id">
                    {{ g.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              class="btn btn-primary md-close"
              type="button"
              @click="getMerchants"
            >
              <span
                v-if="loadingMerchants"
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <i class="sr-only">Loading...</i>
              </span>
              <span v-else>
                <i class="icon icon-left mdi mdi-search" />
                Search
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-distributor-product-permission-view"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Product Permissions</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-4 col-form-label text-sm-right"
                >No Restriction:</label
              >
              <div class="col-12 col-sm-8 col-lg-8 col-form-label text-sm-left">
                <span
                  v-if="distributorProductPermission.noRestriction"
                  class="badge badge-pill badge-success"
                >
                  {{
                    distributorProductPermission.noRestriction ? "YES" : "NO"
                  }}
                </span>
                <span v-else class="badge badge-pill badge-secondary">
                  {{
                    distributorProductPermission.noRestriction ? "YES" : "NO"
                  }}
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-4 col-form-label text-sm-right"
                >Only Sell My Products:</label
              >
              <div class="col-12 col-sm-8 col-lg-8 col-form-label text-sm-left">
                <span
                  v-if="distributorProductPermission.onlySellMyProducts"
                  class="badge badge-pill badge-success"
                >
                  {{
                    distributorProductPermission.onlySellMyProducts
                      ? "YES"
                      : "NO"
                  }}
                </span>
                <span v-else class="badge badge-pill badge-secondary">
                  {{
                    distributorProductPermission.onlySellMyProducts
                      ? "YES"
                      : "NO"
                  }}
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-4 col-form-label text-sm-right"
                >Only Sell Their Products:</label
              >
              <div class="col-12 col-sm-8 col-lg-8 col-form-label text-sm-left">
                <span
                  v-if="distributorProductPermission.onlySellTheirProducts"
                  class="badge badge-pill badge-success"
                >
                  {{
                    distributorProductPermission.onlySellTheirProducts
                      ? "YES"
                      : "NO"
                  }}
                </span>
                <span v-else class="badge badge-pill badge-secondary">
                  {{
                    distributorProductPermission.onlySellTheirProducts
                      ? "YES"
                      : "NO"
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-distributor-product-permission-update"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Update Distributor Product Permissions</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="radio"
                    name="distributorProdPermission"
                    value="noRestriction"
                    v-model="selectedDistributorProductPermission"
                  />
                  <span class="custom-control-label">No Restriction</span>
                </label>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="radio"
                    name="distributorProdPermission"
                    value="onlySellMyProducts"
                    v-model="selectedDistributorProductPermission"
                  />
                  <span class="custom-control-label"
                    >Only Sell My Products</span
                  >
                </label>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="radio"
                    name="distributorProdPermission"
                    value="onlySellTheirProducts"
                    v-model="selectedDistributorProductPermission"
                  />
                  <span class="custom-control-label"
                    >Only Sell Their Products</span
                  >
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              v-if="hasPermission('Distributor_Product_Permission_Update')"
              style="width: 120px"
              class="btn btn-primary md-close"
              type="button"
              @click="updateDistributorProductPermission"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";
import Pagination from "../components/Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "distributors",
  components: {
    Select2,
    Pagination,
  },

  created() {
    if (!this.hasPermission("Distributor_View")) {
      this.$router.push("/restricted");
    }
    // this.getMerchants();
    this.getGroups();
    this.getManufacturers();
  },

  watch: {
    "updatingMerchant.hasCredit": function(newVal, oldVal) {
      if (!newVal) {
        this.updatingMerchant.creditLimit = 0;
      }
    },
  },

  data() {
    return {
      filter: {
        status: "",
        code: "",
        sourceId: "",
        group: "",
        name: "",
      },
      groups: [],
      exportAllLoading: false,
      manufacturers: [],
      deletingMerchant: {},
      updatingMerchant: {},
      v1: {
        creditLimit: false,
      },
      isBusy: false,
      isBusyModal: false,
      distributors: [],
      distributorProductPermission: {},
      selectedDistributorProductPermission: null,
      count: 0,
      page: 0,
      pageNumber: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      exportAllLoading: false,
      loadingMerchants: false,
      showDeactivated: true,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  mounted() {
    this.getMerchants();
  },

  methods: {
    viewDistributorProductPermission(m) {
      this.getProductPermission(m.id);
      $("#mod-distributor-product-permission-view").modal("show");
    },
    viewDistributorProductPermissionForUpdate(m) {
      this.getProductPermission(m.id);
      $("#mod-distributor-product-permission-update").modal("show");
    },
    async getGroups() {
      try {
        this.isBusy = false;

        const response = await this.$http.get(
          `/Groups/getListNoPaging?active=${this.showDeactivated}`
        );

        if (response.ok && response.status === 200) {
          this.groups = response.body;
        }
      } catch (error) {
        this.isBusy = false;
      }
    },

    async download() {
      this.isBusy = true;
      this.exportAllLoading = true;

      try {
        const response = await this.$http.get(
          `distributor/export?active=${status}&accountType=${distributors}&query=${this.filter.qry}`,
          { responseType: "arraybuffer" }
        );

        this.prepareDownload(response);

        this.isBusy = false;
        this.exportAllLoading = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    prepareDownload(response) {
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", DistributorsList);
      document.body.appendChild(link);
      link.click();

      this.getMerchants();
    },

    reset() {
      this.filter = {
        status: "",
        code: "",
        sourceId: "",
        group: "",
        name: "",
      };
    },

    async downloadAll() {
      this.isBusy = true;
      this.exportAllLoading = true;

      try {
        const response = await this.$http.get(
          `/Accounts/distributor/export?` +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            "&accountType=Distributor" +
            "&Code=" +
            this.filter.code +
            "&SourceId=" +
            this.filter.sourceId +
            "&Group=" +
            this.filter.group +
            "&Name=" +
            this.filter.name,
          { responseType: "arraybuffer" }
        );

        this.prepareDownload(response);

        this.isBusy = false;
        this.exportAllLoading = false;
      } catch (error) {
        this.isBusy = false;
        this.exportAllLoading = false;
      }
    },

    prepareDownload(response) {
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Distributors--");
      document.body.appendChild(link);
      link.click();
    },

    viewRemove(m) {
      this.deletingMerchant = m;
      $("#mod-delete").modal("show");
    },

    viewCreditLimit(m) {
      this.updatingMerchant = m;
      $("#mod-update").modal("show");
    },

    manageManufacturers(id) {
      this.$router.push("/distributors/" + id + "/parents");
    },

    viewFilter() {
      this.pageNumber = 1;
      $("#mod-filter").modal("show");
    },

    details(id) {
      this.$router.push("/manufacturer-distributors/" + id);
    },

    updateStatus() {
      this.isBusyModal = true;
      var data = {
        id: this.deletingMerchant.id,
        active: !this.deletingMerchant.active,
      };

      $("#mod-delete").modal("hide");

      this.$http
        .put("/Accounts/updateStatus/" + data.id, data)
        .then(function(res) {
          this.isBusyModal = false;
          this.getMerchants();
        })
        .catch(function() {
          this.isBusyModal = false;
        });
    },

    updateCreditLimits() {
      this.isBusyModal = true;

      if (this.validate()) {
        var data = {
          id: this.updatingMerchant.id,
          creditLimit: this.updatingMerchant.creditLimit,
          hasCredit: this.updatingMerchant.hasCredit,
        };

        $("#mod-update").modal("hide");

        this.$http
          .put("/Accounts/updateCreditLimits/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getMerchants();
          })
          .catch(function() {
            this.isBusyModal = false;
          });
      } else {
        this.isBusyModal = false;
      }
    },

    validate() {
      this.v1 = {
        creditLimit: false,
      };
      var isValid = true;

      if (Number(this.updatingMerchant.creditLimit) < 0) {
        this.v1.creditLimit = true;
        isValid = false;
      }

      return isValid;
    },

    async getMerchants() {
      this.isBusy = true;
      this.loadingMerchants = true;
      await this.$http
        .get(
          "/Accounts/getList?pageNumber=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            "&accountType=Distributor" +
            "&Code=" +
            this.filter.code +
            "&SourceId=" +
            this.filter.sourceId +
            "&Group=" +
            this.filter.group +
            "&Name=" +
            this.filter.name
        )
        .then(function(res) {
          this.distributors = res.body.data;
          this.page = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
      this.loadingMerchants = false;
    },
    async getProductPermission(distributorId) {
      this.isBusy = true;

      await this.$http
        .get("/Accounts/getDistributorProductPermission/" + distributorId)
        .then(function(res) {
          this.distributorProductPermission = res.body;
          if (this.distributorProductPermission.noRestriction)
            this.selectedDistributorProductPermission = "noRestriction";
          else if (this.distributorProductPermission.onlySellMyProducts)
            this.selectedDistributorProductPermission = "onlySellMyProducts";
          if (this.distributorProductPermission.onlySellTheirProducts)
            this.selectedDistributorProductPermission = "onlySellTheirProducts";
          this.isBusy = false;
        })
        .catch(function() {});

      this.isBusy = false;
    },

    async updateDistributorProductPermission() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        distributorId: this.distributorProductPermission.distributorId,
        manufacturerId: this.distributorProductPermission.manufacturerId,
        noRestriction:
          this.selectedDistributorProductPermission == "noRestriction",
        onlySellMyProducts:
          this.selectedDistributorProductPermission == "onlySellMyProducts",
        onlySellTheirProducts:
          this.selectedDistributorProductPermission == "onlySellTheirProducts",
        id: this.distributorProductPermission.id,
      };

      $("#mod-distributor-product-permission-update").modal("hide");

      await this.$http
        .put("/Accounts/updateDistributorProductPermission/" + data.id, data)
        .then(function(res) {
          this.isBusyModal = false;
        })
        .catch(function() {});

      this.isBusy = false;
      this.isBusyModal = false;
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    prev() {
      this.pageNumber -= 1;
      this.getMerchants();
    },
    next() {
      this.pageNumber += 1;
      this.getMerchants();
    },

    gotoPage(page) {
      this.pageNumber = page;
      this.getMerchants();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getMerchants();
    },

    async getManufacturers() {
      await this.$http
        .get("/Accounts/getItemList?accountType=Manufacturer")
        .then(function(res) {
          this.manufacturers = res.body;
        })
        .catch(function() {});
    },
  },
};
</script>
