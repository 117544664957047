<template>
  <button
    class="btn btn-danger btn-sm btn-rounded btn-space mr-1"
    data-toggle="tooltip"
    title="Delete"
    @click.prevent="deletePurchaseItemDetail"
  >
    <span>
      <i class="icon mdi mdi-delete text-white"></i>
      Remove Item
    </span>
  </button>
</template>

<script>
export default {
  name: "DeletePurchaseOrderItemDetail",
  props: ["item"],

  methods: {
    onDeleteClick() {
      this.$emit("showAction");
    },

    async deletePurchaseItemDetail() {
      this.$emit("onRemove", this.item.id);
    },
  },
};
</script>

<style>
</style>