<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-delete-caution"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h4 class="text-center p-1">
            <i class="fa fa-warning text-warning"></i>
            <strong class="ml-1">Caution</strong>
          </h4>
          <p class="text-center">
            Price option will be removed from Groups and Distributors.
          </p>
        </div>
        <div
          class="
            modal-footer
            border-0
            d-flex
            justify-content-center
            align-items-center
          "
        >
          <button class="btn btn-secondary" type="button" data-dismiss="modal">
            Cancel
          </button>
          <button class="btn btn-danger" type="button" @click.prevent="accept">
            Remove Option
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RRPDeleteCaution",
  props: ["id"],
  methods: {
    accept() {
      this.$emit("proceed", this.id);
    },
  },
};
</script>

<style>
</style>