<template>
      <div class="modal fade colored-header colored-header-primary" id="mod-read-broadcast" role="dialog">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
                <div>
                    <h4 class="modal-title"><b>Message Preview</b></h4>
                </div>
                <button
                    class="close md-close"
                    type="button"
                    data-dismiss="modal"
                    aria-hidden="true"
                    @click.prevent="closeDialog"
                >
                    <span class="mdi mdi-close"></span>
                </button>
            </div>
            <div class="modal-body p-3">
                <div class="mb-2 row">
                    <div class="col col-sm-12 mb-1">
                        <span class="font-weight-bold">{{details.sender}}</span><span> - {{details.date}}</span>
                    </div>
                    <div class="col col-sm-12 mb-2">
                        <h4><b>{{details.title}}</b></h4>
                    </div>
                    <div v-if="details.imagePosition === 'top'" class="col col-sm-12 col-md-6 py-2">
                        <img alt="attachment"  height="auto" width="240px" class="logoImage" :src="`${details.imageAttach}`"/>
                    </div>
                    <div class="col col-sm-12">
                        <p> {{details.message}}</p>
                    </div>
                     <div v-if="details.imagePosition === 'bottom'" class="col col-sm-12 col-md-6 py-2">
                        <img alt="attachment"  height="180px" width="240px" class="logoImage" :src="`${details.imageAttach}`"/>
                    </div>
                    <div v-if="details.infoUrl" class="col col-sm-12">
                        <p> <span class="font-weight-bold">Useful link: </span><a :href="details.infoUrl">{{details.infoUrl}}</a></p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
              <button
                style="width: 100px;"
                class="btn btn-primary"
                type="button"
                data-dismiss="modal"
                @click.prevent="closeDialog"
              >
                Done
              </button>

            </div>
          </div>
        </div>
      </div>
</template>

<script>

export default {
  name: "view-broadcast",
  props: ["details"],

  data() {
    return {
      isPosting: false,
    };
  },

  methods: {
    closeDialog() {
      this.$emit("closeModal");
    },
 
  },
};
</script>
