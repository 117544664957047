<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="goBack">
            <i class="mdi icon mdi-backburger text-white"></i>
            Back
          </button>
          <div
            class="btn-group"
            role="group"
            aria-label="Purchase Orders Actions"
          >
            <button
              class="btn btn-success btn-rounded btn-space"
              @click="viewFilter"
            >
              <span class="feather icon-filter text-white"></span> Filter
            </button>
            <button class="btn btn-success" @click="downloadAll">
              <span
                v-if="exportAllLoading"
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <i class="sr-only">Loading...</i>
              </span>
              <span v-else>
                <i class="icon icon-left mdi mdi-arrow-top-right" />
                Export
              </span>
            </button>
            <router-link
              v-if="hasPermission('Sales_Order_Create')"
              class="btn btn-success"
              to="/sales-order/upload"
            >
              <i class="mdi mdi-cloud-upload" />
              Upload Sales Order</router-link
            >
          </div>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th>
                        <button
                          :disabled="selectedPurchaseOrders.length <= 0"
                          class="btn btn-success btn-sm"
                          type="button"
                          @click="downloadSelected"
                        >
                          <span
                            v-if="exportSelectedLoading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <i class="sr-only">Loading...</i>
                          </span>
                          <span v-else>
                            <i class="icon icon-left mdi mdi-arrow-top-right" />
                            Export
                            {{
                              selectedPurchaseOrders.length > 0
                                ? selectedPurchaseOrders.length
                                : ""
                            }}
                          </span>
                        </button>
                      </th>
                      <th>Distributor Name</th>
                      <th>PO Number</th>
                      <th>Order Amount</th>
                      <th>Created</th>
                      <th>Updated</th>
                      <th>Status</th>
                      <th>Stage</th>
                      <th>Read Status</th>
                      <th>Downloaded</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="o in purchaseOrders.purchaseOrders" :key="o.id">
                      <td>
                        <PurchaseOrderCheckBox
                          :purchaseOrder="o"
                          @onPurchaseAdd="onPurchaseAddHandler"
                          @onPurchaseRemove="onPurchaseRemoveHandler"
                        />
                      </td>
                      <td>{{ o.distributorName }}</td>
                      <td>{{ o.orderNumber }}</td>
                      <Amount :details="o.details" :orderItems="o.orderItems" />
                      <td>{{ formatDate(o.created) }}</td>
                      <td>{{ formatDate(o.updated) }}</td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            {
                              'badge-success': o.status === 'confirmed',
                            },
                            {
                              'badge-danger': o.status === 'withdrawn',
                            },
                            {
                              'badge-secondary':
                                o.status === 'draft' || o.status === 'CLOSED',
                            },
                            {
                              'badge-warning': o.status === 'submitted',
                            },
                            {
                              'badge-dark': o.status === 'recalled',
                            },
                          ]"
                          >{{ o.status }}</span
                        >
                      </td>
                      <td>
                        {{ o.stage ? o.stage.name : "No stage" }}
                      </td>
                      <td>{{ o.read ? "Read" : "Unread" }}</td>
                      <td>{{ o.isDownloaded ? "YES" : "NO" }}</td>
                      <td>
                        <div class="d-flex">
                          <router-link
                            v-if="
                              o.status !== 'confirmed' &&
                                o.status != 'CLOSED' &&
                                hasPermission('Sales_Order_Update')
                            "
                            class="
                              btn btn-success btn-sm btn-rounded btn-space
                              mr-1
                            "
                            :to="{
                              path: `/sales-order/${o.id}/edit`,
                            }"
                          >
                            <i
                              class="
                                icon
                                mdi mdi-circle-edit-outline
                                text-white
                              "
                            ></i>
                            Edit
                          </router-link>
                          <router-link
                            class="
                              btn btn-success btn-sm btn-rounded btn-space
                              mr-1
                            "
                            :to="{
                              path: `/sales-order/${o.id}/view`,
                            }"
                          >
                            <i
                              class="icon mdi mdi-view-parallel text-white"
                            ></i>
                            View
                          </router-link>
                          <button
                            v-if="
                              o.status != 'CLOSED' &&
                                hasPermission('Sales_Order_Process')
                            "
                            class="
                              btn
                              btn-outline-primary
                              btn-sm
                              btn-rounded
                              btn-space
                            "
                            @click.prevent="startProcess(o)"
                          >
                            Process
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="purchaseOrders.pageSize"
                  :count="purchaseOrders.pageCount"
                  :page="purchaseOrders.page"
                  :itemsCount="purchaseOrders.count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ purchaseOrders.count }} Sales Order{{
                    purchaseOrders.count != 1 ? "s" : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProcessModal :order="order" @onComplete="onComplete" />
    <FilterPurchaseOrder
      @onFilter="onFilterHandler"
      @onClear="onFilterHandler"
    />
  </div>
</template>

<script>
import moment from "moment";
import Amount from "../../components/SettingsPurchaseOrder/Amount";
import ProcessModal from "../../components/SettingsPurchaseOrder/ProcessModal";
import FilterPurchaseOrder from "../../components/SettingsPurchaseOrder/FilterPurchaseOrder.vue";
import PurchaseOrderCheckBox from "../../components/SettingsPurchaseOrder/PurchaseOrderCheckBox.vue";
import { mapActions, mapGetters } from "vuex";
import SystemPermissions from "@/permissions";
import Pagination from "../../components/Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  components: {
    Amount,
    ProcessModal,
    FilterPurchaseOrder,
    PurchaseOrderCheckBox,
    Pagination,
  },

  data() {
    return {
      isBusy: false,
      order: {},
      selectedPurchaseOrders: [],
      exportAllLoading: false,
      exportSelectedLoading: false,
    };
  },

  async created() {
    if (!this.hasPermission("Sales_Order_View")) {
      this.$router.push("/restricted");
    }
    if (this.purchaseOrders.purchaseOrders.length <= 0)
      await this.getPurchaseOrder();
  },

  computed: {
    ...mapGetters(["purchaseOrders"]),
    filter: {
      get() {
        return this.$store.state.filter;
      },
    },
    dates: {
      get() {
        return this.$store.state.dates;
      },
    },
  },

  methods: {
    ...mapActions(["getPurchaseOrder"]),
    getPurchaseOrder() {
      this.$store.dispatch("getPurchaseOrder", "enterprise");
    },

    setDate(date) {
      this.$store.commit("setDate", date);
    },

    onFilterHandler(dates) {
      if (dates) this.setDate(dates);
      this.getPurchaseOrder();
      // eslint-disable-next-line no-undef
      $("#mod-purchase-order-filter").modal("hide");
    },

    viewFilter() {
      // eslint-disable-next-line no-undef
      this.purchaseOrders.pageNumber = 0;
      $("#mod-purchase-order-filter").modal("show");
    },

    startProcess(order) {
      if (order.status === "confirmed") {
        this.$router.push(`/sales-order/${order.id}/process`);
      } else if (order.status === "submitted") {
        this.order = order;

        // eslint-disable-next-line no-undef
        $("#mod-process").modal("show");
      }
    },

    onPurchaseAddHandler(value) {
      this.selectedPurchaseOrders.push(value);
    },

    onPurchaseRemoveHandler(value) {
      this.selectedPurchaseOrders = this.selectedPurchaseOrders.filter(
        (x) => x != value
      );
    },

    async downloadAll() {
      this.isBusy = true;
      this.exportAllLoading = true;

      try {
        const response = await this.$http.get(
          `/PurchaseOrders/download?CustomerName=${this.filter.customerName}&StartDate=${this.dates.startDate}&EndDate=${this.dates.endDate}&PageNumber=${this.purchaseOrders.pageNumber}&PageSize=${this.purchaseOrders.pageSize}`,
          { responseType: "arraybuffer" }
        );

        this.prepareDownload(response);
        this.isBusy = false;
        this.exportAllLoading = false;
      } catch (error) {
        this.isBusy = false;
        this.exportAllLoading = false;
      }
    },

    async downloadSelected() {
      this.isBusy = true;
      this.exportSelectedLoading = true;

      try {
        const response = await this.$http.post(
          "/PurchaseOrders/download/selected",
          this.selectedPurchaseOrders,
          { responseType: "arraybuffer" }
        );

        this.prepareDownload(response);

        this.isBusy = false;
        this.exportSelectedLoading = false;
      } catch (error) {
        this.isBusy = false;
        this.exportSelectedLoading = false;
      }
    },

    prepareDownload(response) {
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "SalesOrder");
      document.body.appendChild(link);
      link.click();

      this.getPurchaseOrder();
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    onComplete() {
      this.getPurchaseOrder();
      this.$router.push(`/sales-order/${this.order.id}/process`);
    },
    prev() {
      this.purchaseOrders.pageNumber -= 1;
      this.getPurchaseOrder();
    },
    next() {
      this.purchaseOrders.page += 1;
      this.getPurchaseOrder();
    },

    gotoPage(page) {
      this.purchaseOrders.page = page;
      this.getPurchaseOrder();
    },

    changeLimit(limit) {
      this.purchaseOrders.pageSize = limit;
      this.getPurchaseOrder();
    },
  },

  mounted() {
    this.$store.commit("clearFilter");
    this.getPurchaseOrder();
  },
};
</script>

<style></style>
