<template>
	<div
		class="modal fade colored-header colored-header-primary"
		id="mod-add-price-option"
		role="dialog"
	>
		<div class="modal-dialog">
			<form class="modal-content">
				<div class="mt-1">
					<h4 class="p-1 text-center">
						<b>Add Price Option To Group</b>
					</h4>
				</div>
				<div class="modal-body">
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right"
							>Search for product</label
						>
						<div class="col-12 col-sm-7 col-lg-7">
							<multiselect
								v-model="value"
								label="name"
								track-by="name"
								:preserve-search="true"
								:options="products"
								@select="onSelect"
							></multiselect>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right"
							>Choose product price option to apply for group</label
						>
						<div v-if="productId && !isBusyModal" class="col-12 col-sm-7 col-lg-7">
							<div class="custom-control custom-radio">
								<input
									type="radio"
									id="default"
									name="customRadio"
									class="custom-control-input"
									value="default"
									v-model="selectedOption"
									:disabled="options.length <= 0 || !isOptionApplied"
								/>
								<label class="custom-control-label" for="default">
									Default
								</label>
							</div>
							<div
								v-for="option in options"
								:key="option.id"
								class="custom-control custom-radio"
							>
								<input
									type="radio"
									:id="option.id"
									name="customRadio"
									class="custom-control-input"
									:value="option.id"
									v-model="selectedOption"
									:disabled="option.isApplied"
								/>
								<label class="custom-control-label" :for="option.id">{{
									option.name
								}}</label>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer border-0">
					<button
						style="width: 100px"
						class="btn btn-danger"
						type="button"
						data-dismiss="modal"
						@click="reset"
					>
						Cancel
					</button>
					<button
						:disabled="isBusyModal || options.length <= 0"
						class="btn btn-primary"
						@click.prevent="submit"
						type="submit"
					>
						<span
							v-if="isBusyModal"
							class="spinner-border spinner-border-sm"
							role="status"
						>
							<i class="sr-only">Loading...</i>
						</span>

						<span v-else>
							<i class="icon mdi mdi-file-add text-white"></i>
							Add Price Option
						</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import Multiselect from "vue-multiselect";
	export default {
		name: "AddPriceOption",
		components: { Multiselect },

		props: ["groupId"],

		data() {
			return {
				isBusy: false,
				isBusyModal: false,
				value: "",
				products: [],
				options: [],
				selectedOption: "",
				productId: "",
				isOptionApplied: false,
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},

		mounted() {
			this.getProducts();
		},

		methods: {
			async getProducts() {
				this.isBusy = true;
				this.isBusyModal = true;
				try {
					const response = await this.$http.get(
						"/products/getListNoPagingForManufacturer?parentId=" +
							this.user.parentId +
							"&active=true",
					);

					if (response.ok)
						this.products = response.body.sort(function(x, y) {
							let a = x.name.toUpperCase(),
								b = y.name.toUpperCase();
							return a == b ? 0 : a > b ? 1 : -1;
						});

					this.isBusy = false;
					this.isBusyModal = false;
				} catch (error) {
					this.isBusy = false;
					this.isBusyModal = false;
				}
			},

			async getOptions() {
				this.isBusy = true;
				this.isBusyModal = true;
				this.options = [];

				try {
					const response = await this.$http.get(
						"/PriceOptions/getListByProductId/" + this.productId + "/" + this.groupId,
					);

					if (response.ok) {
						this.options = response.body.sort(function(x, y) {
							let a = x.name.toUpperCase(),
								b = y.name.toUpperCase();
							return a == b ? 0 : a > b ? 1 : -1;
						});
						this.checkExist();
					}

					this.isBusy = false;
					this.isBusyModal = false;
				} catch (error) {
					this.isBusy = false;
					this.isBusyModal = false;
				}
			},

			checkExist() {
				this.options.forEach(element => {
					if (element.isApplied) {
						this.isOptionApplied = true;
					}
				});
			},

			onSelect(option) {
				this.productId = option.id;
				this.getOptions();
			},

			async submit() {
				this.isBusy = true;
				this.isBusyModal = true;

				try {
					const data = {
						groupId: this.groupId,
						optionId: this.selectedOption,
						productId: this.productId,
					};

					const response = await this.$http.post("/Groups/addPriceOption", data);
					if (response.ok) {
						this.$toast.success(
							this.isOptionApplied ? "Price option updated!" : "Price option added!",
							{
								icon: false,
								rtl: false,
							},
						);
						this.reset();
						this.$emit("onComplete");
					}

					this.isBusy = false;
					this.isBusyModal = false;
				} catch (error) {
					this.isBusy = false;
					this.isBusyModal = false;
				}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			reset() {
				this.options = [];
				this.selectedOption = "";
				this.productId = "";
				this.value = "";
				this.isOptionApplied = false;
			},
		},
	};
</script>
