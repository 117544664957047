<template>
  <div class="modal fade" id="mod-confirm-delete" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-4">
          <p class="text-center" v-if="!message">
            Are you sure you want to delete this {{ text }}? This action can not be reversed.
          </p>
          <p class="text-center" v-if="message">
            Deleting this warehouse will delete associated storage areas. Click DELETE to proceed.
          </p>
          <div class="mt-3 d-flex justify-content-between align-items-center">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click.prevent="cancelConfirm"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusy"
              class="btn btn-primary"
              type="button"
              @click.prevent="handleDelete"
            >
              <span v-if="isBusy">Busy...</span>
              <span v-else>{{ message ? "Delete" : "Confirm" }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralDeleteCaution",
  props: ["id", "isBusy", "text", "message"],
  methods: {
    handleDelete() {
      this.$emit("proceed", this.id);
    },
    cancelConfirm() {
      this.$emit("cancel");
    },
  },
};
</script>
