<template>
    <div class="be-splash-screen">
        <!--<div class="be-wrapper be-login">
      <div class="be-content">
        <div class="main-content container-fluid">
          <div class="splash-container forgot-password">
            <div class="card card-border-color card-border-color-primary">
              <div class="card-header">
                <img
                  class="logo-img"
                  src="/assets/img/logo-xx.png"
                  alt="logo"
                  width="200"
                  height="60"
                >
                <h4>Reset Password</h4>
                <span class="splash-description">Forgot your password? Please enter your registered email address.</span>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group pt-4">
                    <input
                      :class="['form-control', { 'is-invalid':$v.user.emailAddress.$error }]"
                      type="email"
                      placeholder="Your Email"
                      autocomplete="off"
                      v-model.trim="$v.user.emailAddress.$model"
                    >
                    <ul
                      v-if="!$v.user.emailAddress.required && $v.user.emailAddress.$dirty"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                    <ul v-if="!$v.user.emailAddress.email" class="parsley-errors-list filled">
                      <li class="parsley-required">Enter a valid email address.</li>
                    </ul>
                  </div>

                  <div class="form-group pt-1">
                    <button
                      :disabled="isBusy"
                      class="btn btn-block btn-primary btn-xl"
                      @click.prevent="reset"
                    >
                      <span v-if="isBusy">Busy...</span>
                      <span v-else>Reset password</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
        <div class="forny-container">
            <div class="forny-inner">
                <div class="forny-two-pane">
                    <div>
                        <div class="forny-form">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">

                                </li>
                            </ul>

                            <div class="tab-content">
                                <div class="tab-pane fade show active" role="tabpanel" id="login">
                                    <a class="nav-link active bg-transparent" href="#login" data-toggle="tab" role="tab">
                                        <span>
                                            <img class="logo-img"
                                                 src="/assets/img/logo-xx.png"
                                                 alt="logo"
                                                 width="250"
                                                 height="80">
                                        </span>
                                    </a>
                                    <h1 class="font-weight-bold mt-3">
                                        Reset Your Password
                                    </h1>
                                    <p v-if="mode=='email'" class="mt-4 mb-6 font-medium-2">
                                        Forgot your password? Please enter your registered email address.
                                    </p>
                                    <p v-else class="mt-4 mb-6 font-medium-2">
                                        <span v-if="phoneValStep === 1"> Please enter your phone number to receive a one time verification code (Merchant Only). </span>
                                        <span v-if="phoneValStep === 2"> Enter 6-digit validation code received. </span>
                                        <span v-if="phoneValStep === 3"> Please enter your new pasword.</span>
                                    </p>
                                    <div v-if="mode == 'email'">
                                        <form>
                                          <div class="form-group">
                                              <input :class="['form-control', 'font-medium-2', { 'is-invalid':$v.user.emailAddress.$error }]"
                                                    type="email"
                                                    placeholder="Your Email"
                                                    autocomplete="off"
                                                    v-model.trim="$v.user.emailAddress.$model">
                                              <ul v-if="!$v.user.emailAddress.required && $v.user.emailAddress.$dirty"
                                                  class="parsley-errors-list filled">
                                                  <li class="parsley-required text-danger">Required.</li>
                                              </ul>
                                              <ul v-if="!$v.user.emailAddress.email" class="parsley-errors-list filled">
                                                  <li class="parsley-required text-danger">Enter a valid email address.</li>
                                              </ul>
                                          </div>

                                          <div class="my-2" style="cursor:pointer" @click.prevent="changeMode('phone')">
                                              <p class="text-primary">Use Phone Number (Merchant Only)</p>
                                          </div>

                                          <div class="form-group">
                                              <button :disabled="isBusy"
                                                      class="btn btn-block btn-primary btn-lg p-1"
                                                      @click.prevent="reset">
                                                  <i class="feather icon-lock"></i> &nbsp;
                                                  <span v-if="isBusy">Busy...</span>
                                                  <span v-else>Reset password</span>
                                              </button>

                                          </div>
                                          <p class="text-center">
                                              <router-link to="/login">Back to Login</router-link>
                                          </p>
                                      </form>
                                    </div>

                                    <div v-if="mode == 'phone'">
                                        <form v-if="phoneValStep === 1">
                                          <label>Phone Number</label>
                                          <div class="form-group">
                                            	<div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text" style="font-size: 14px; font-weight: bold;margin-top: 4px; border-right:1px soid gray">
                                                    +234
                                                  </span>
                                                </div>

                                                <input
                                                  :class="[
                                                    'form-control',
                                                    'font-medium-1',
                                                  ]"
                                                  type="text"
                                                  placeholder="10 digits"
                                                  autocomplete="off"
                                                  v-model="user.phone"
                                                />
                                              </div>
                                              <p
                                                  v-if="
                                                    !phoneValidation.phoneValid
                                                  "
                                                  class="parsley-errors-list filled"
                                                >
                                                  <span
                                                    class="parsley-required text-danger font-small-2"
                                                    >* 10 digit phone number required.</span
                                                  >
                                                </p>
                                          </div>

                                          <div class="my-2" style="cursor:pointer" @click.prevent="changeMode('email')">
                                              <p class="text-primary">Use Email Address</p>
                                          </div>

                                          <div class="form-group">
                                              <button :disabled="isBusyStep1"
                                                class="btn btn-block btn-primary btn-lg p-1"
                                                @click.prevent="sendPhoneCode"
                                              >
                                                  <i class="feather icon-lock"></i> &nbsp;
                                                  <span v-if="isBusyStep1">Busy...</span>
                                                  <span v-else>Send Code</span>
                                              </button>

                                          </div>
                                          <p class="text-center">
                                              <router-link to="/login">Back to Login</router-link>
                                          </p>
                                      </form>

                                       <form v-if="phoneValStep === 2">
                                          <!-- <div class="form-group"> -->
                                              	<div class="row">
                                                  <label class="col-12"
                                                    ><span class="font-weight-bold">Enter OTP</span></label
                                                  >
                                                  <div class="col-12">
                                                    <otp-input
                                                      :isValid="verifOTPValid"
                                                      :digits="6"
                                                      type="number"
                                                      @on-complete="onCompleteOTPHandler"
                                                      @on-changed="onChangedOTPHandler"
                                                      @on-paste="onPasteOTPHandler">
                                                        <template #errorMessage> OTP is not valid </template>
                                                    </otp-input>

                                                    <div v-if="isBusyOtp" class="my-2">Resending OTP...</div>
                                                    <div v-else class="my-2">
                                                      <span v-if="timerValue > 0">Resending in {{prettyTime}}</span>
                                                      <button v-else @click.prevent="resendPhoneOtp" class="btn btn-text">Didn't get OTP?  Resend </button>
                                                    </div>
                                                  </div>
                                                </div>
                                          <!-- </div> -->                                        

                                          <div class="form-group mt-2">
                                              <button :disabled="isBusyStep2"
                                                class="btn btn-block btn-primary btn-lg p-1"
                                                @click.prevent="validatePhoneCode"
                                              >
                                                  <i class="feather icon-lock"></i> &nbsp;
                                                  <span v-if="isBusyStep2">Busy...</span>
                                                  <span v-else>Send Code</span>
                                              </button>

                                          </div>
                                          <p class="text-center">
                                              <router-link to="/login">Back to Login</router-link>
                                          </p>
                                      </form>

                                       <form v-if="phoneValStep === 3">
                                         <div class="my-2">
                                          <label>PLEASE ENTER YOUR NEW PASSWORD</label>
                                          	<div class="input-group">
                                              <input
                                                style="border:1px solid gray"
                                                :class="[
                                                  'form-control',
                                                  'font-medium-1',
                                                ]"
                                                :type="[
                                                  toggledViewPassword ? 'text' : 'password',
                                                ]"
                                                placeholder="Enter Password"
                                                autocomplete="off"
                                                v-model.trim="user.password"
                                              />
                                              <div class="input-group-prepend cursor-pointer">
                                                <span
                                                  class="input-group-text d-flex justify-content-center"
                                                  @click="
                                                    toggledViewPassword = !toggledViewPassword
                                                  "
                                                >
                                                  <i
                                                    class="fa"
                                                    :class="[
                                                      toggledViewPassword
                                                        ? 'fa-eye'
                                                        : 'fa-eye-slash',
                                                    ]"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                              </div>
                                            </div>

                                            <p v-if="!phoneValidation.passwordValid" class="parsley-errors-list filled">
                                              <span class="parsley-required text-danger font-small-2">* Minimum of 8 characters required.</span>
                                            </p>
                                          </div>

                                          <div class="my-1">
                                            <label>CONFIRM PASSWORD BY ENTERING IT AGAIN</label>
                                          	<div class="input-group">
                                              <input
                                                style="border:1px solid gray"
                                                :class="[
                                                  'form-control',
                                                  'font-medium-1',
                                                ]"
                                                :type="[
                                                  toggledViewPasswordConfirm ? 'text' : 'password',
                                                ]"
                                                placeholder="Enter Password"
                                                autocomplete="off"
                                                v-model.trim="user.confirmPassword"
                                              />
                                              <div class="input-group-prepend cursor-pointer">
                                                <span
                                                  class="input-group-text d-flex justify-content-center"
                                                  @click="
                                                    toggledViewPasswordConfirm = !toggledViewPasswordConfirm
                                                  "
                                                >
                                                  <i
                                                    class="fa"
                                                    :class="[
                                                      toggledViewPasswordConfirm
                                                        ? 'fa-eye'
                                                        : 'fa-eye-slash',
                                                    ]"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                              </div>
                                            </div>
                                             <p v-if="!phoneValidation.confirmPasswordValid" class="parsley-errors-list filled">
                                              <span class="parsley-required text-danger font-small-2">* Minimum of 8 characters required.</span>
                                              </p>
                                          </div>

                                          <p v-if="!phoneValidation.passwordMatch" class="parsley-errors-list filled">
                                              <span class="parsley-required text-danger font-small-2">* Password and Confirm password must match.</span>
                                          </p>
                                      
                                          <div class="form-group mt-2">
                                              <button :disabled="isBusyStep3"
                                                class="btn btn-block btn-primary btn-lg p-1"
                                                @click.prevent="confirmNewPassword"
                                              >
                                                  <i class="feather icon-lock"></i> &nbsp;
                                                  <span v-if="isBusyStep3">Busy...</span>
                                                  <span v-else>Reset Password</span>
                                              </button>

                                          </div>
                                          <p class="text-center">
                                              <router-link to="/login">Back to Login</router-link>
                                          </p>
                                      </form>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right-pane">
                        <div class="text-center" style="width: 300px; margin-top:-140px">
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div
          class="modal fade colored-header colored-header-primary"
          id="mod-phone-password-change"
          role="dialog"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header modal-header-colored">
                <h4 class="modal-title p-1">
                  <strong>Reset Password</strong>
                </h4>
              </div>
             <div class="modal-body">
              <div class="parent-div success-div">
                <div class="first-child">
                  <div class="second-child">
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none">
                      <path
                        d="M19.599 0.410713C19.2129 0.0245541 18.5869 0.0245541 18.2007 0.410713L6.35464 12.2568L1.80123 7.70343C1.41511 7.31727 0.789098 7.31731 0.4029 7.70343C0.0167416 8.08955 0.0167416 8.71556 0.4029 9.10172L5.65548 14.3542C6.04148 14.7403 6.66796 14.7401 7.05381 14.3542L19.599 1.80904C19.9851 1.42292 19.9851 0.796872 19.599 0.410713Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
                
               <p class="text-center">Your pasword has been reset successfully, proceed to login</p>
                <div class="text-right w-100 mt-2">
                  <button class="btn btn-primary btn-md" @click.prevent="gotoLogin">Procced to Login</button>
                </div>
             </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { helper } from "@/helper";
import { required, email } from "vuelidate/lib/validators";
import OtpInput from "otp-input-vue2";

export default {
  name: "forgotPassword",
  components: {
			OtpInput,
  },
  data() {
    return {
      isBusy: false,
      user: {
        emailAddress: "",
        phone: "",
        password: "",
        confirmPassword: ""
      },
      type: "password",
      toggledViewPassword: false,
      typeConfirm: "password",
      toggledViewPasswordConfirm: false,
      mode:"email",
      phoneValStep: 1,

      phoneValidation: {
        phoneValid: true,
        passwordValid: true,
        confirmPasswordValid: true,
        passwordMatch: true,
      },

      timerSeconds: "00",
      timerValue: 300,
      timer: null,
      timerStarted: false,  

      verifOTP: "",
      verifOTPValid: true,
      isBusyStep1: false,
      isBusyStep2: false,
      isBusyStep3: false,
      isBusyOtp: false
    };
  },
  validations: {
    user: {
      emailAddress: { required, email }
    }
  },

  computed: {
    prettyTime () {
				let time = this.timerValue / 60
				let minutes = parseInt(time);
				let minutesShow = minutes > 1 ? `${minutes} minutes` :  `${minutes} minute`;
				let roundedSeconds = Math.round((time - minutes) * 60);
				let secondes = roundedSeconds > 1 ? `${roundedSeconds} seconds` : `${roundedSeconds} second`;
				return minutesShow+" "+secondes
			}
  },

  methods: {
    changeMode(md) {
      this.mode = md;
    },

    gotoLogin(){
      $("#mod-phone-password-change").modal("hide");
      this.$router.push('/login')
    },

    phoneStepOneValid(){
      let isvalid = true;
      if(this.user.phone === "" || this.user.phone.length !== 10){
        isvalid = false;
        this.phoneValidation.phoneValid = false;
      }
      return isvalid;
    },

    phoneStepTwoValid(){
      let isvalid = true;
      if(this.verifOTP === "" || this.verifOTP.length !== 6){
        isvalid = false;
        this.verifOTPValid = false;
      }
      return isvalid;
    },

    phoneStepThreeValid(){
      let isvalid = true;
      if(this.user.password === "" || this.user.password.length < 8){
        isvalid = false;
        this.phoneValidation.passwordValid = false;
      }else if(this.user.confirmPassword === "" || this.user.confirmPassword.length < 8){
        isvalid = false;
        this.phoneValidation.confirmPasswordValid = false;
      }else if(this.user.password !== this.user.confirmPassword){
        isvalid = false;
        this.phoneValidation.passwordMatch = false;
      }else{
        return isvalid;
      }
    },

    sendPhoneCode(){
      if(this.phoneStepOneValid()){
        this.isBusyStep1 = true;
        this.$http.post(`/Users/sendToken?phoneNumber=234${this.user.phone}`)
        .then(function(res) {
          if(res.body){
            this.resetTime();
            this.start();
            this.isBusyStep1 = false;
            this.phoneValStep = 2;
          }else{
            this.$toast.error("Error sending OTP", {
              icon: true,
              rtl: false,
            });
          }
        })
        .catch(function() {
          this.isBusyStep1 = false;
        });
      }
    },

    validatePhoneCode(){
      this.isBusyStep2 = true;
      if(this.phoneStepTwoValid()){
        this.isBusyStep2 = false;
        this.phoneValStep = 3;
      }else{
        this.isBusyStep2 = false;
      }
    },

    confirmNewPassword(){
      if(this.phoneStepThreeValid()){
        this.isBusyStep3 = true;

        this.$http.post(`/Users/changePassword?phoneNo=234${this.user.phone}&password=${this.user.password}&confirmPasword=${this.user.confirmPassword}&token=${this.verifOTP}`)
        .then(function(res) {
          if(res.body){
            this.resetTime();
            $("#mod-phone-password-change").modal("show");
            this.isBusyStep3 = false;
            this.verifOTPValid = false;
          }else{
            this.$toast.error("Error validating OTP", {
              icon: true,
              rtl: false,
            });
          }
        })
        .catch(function() {
          this.isBusyStep3 = false;
        });
      }
    },

    start () {
				this.timerStarted = true
				if (!this.timer) {
					this.timer = setInterval( () => {
							if (this.timerValue > 0) {
								this.timerValue--
							} else {
								clearInterval(this.timer)
								// this.resetTime()
							}
					}, 1000 )
				}
			},
			stop () {
				this.timerStarted = false
				clearInterval(this.timer)
				this.timer = null
			},
			resetTime () {
				this.stop()
				this.timerValue = 300
			},

    onCompleteOTPHandler(code){
      this.verifOTP = code;
    },

    onChangedOTPHandler(lastEnteredCode){
      // console.log("code changed",  lastEnteredCode);
    },

    onPasteOTPHandler(code){
      this.verifOTP = code;
    },

    resendPhoneOtp(){
      this.isBusyOtp = true;
        this.$http.post(`/Users/sendToken?phoneNumber=234${this.user.phone}`)
						.then(function(res) {
							if(res.body){
								this.resetTime();
								this.start();
                this.isBusyOtp = false;
							}else{
								this.$toast.error("Error resending OTP", {
									icon: true,
									rtl: false,
								});
							}
						})
						.catch(function() {
							this.isBusyOtp = false;
						});
		},

    async reset() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isBusy = true;
        let body = {
          email: this.user.emailAddress
        }

        await this.$http
          .put(`/Users/resetPassword`, body)
          .then(function(res) {
            $.gritter.add({
              title: "Password reset",
              text: "Your new password has been sent to your email address.",
              class_name: "color success"
            });

            this.$router.push("/login");
          })
          .catch(function() {});

        this.isBusy = false;
      }
    }
  }
};
</script>

<style scoped>
.parent-div {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto 34px;
  width: 110px;
  height: 110px;
}

.first-child {
  width: 86.52px;
  height: 86.52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-child {
  width: 61.8px;
  height: 61.8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-div {
  border: 1px solid rgba(182, 201, 121, 0.2);
}

.success-div .first-child {
  border: 1px solid rgba(182, 201, 121, 0.5);
}

.success-div .second-child {
  background: rgba(182, 201, 121, 1);
}

.text-bold {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
</style>
