<template>
    <select>
        <option></option>
        <slot></slot>
    </select>
</template>

<script>
/* eslint-disable */

export default {
  name: "select2",
  props: ["options", "value", "placeholder"],
  mounted() {
    var vm = this;
    $(this.$el)
      .select2({
        data: this.options,
        width: "100%",
        placeholder: this.placeholder
      })
      .val(this.value)
      .trigger("change")
      .on("change", function() {
        vm.$emit("input", this.value);
      });
  },
  watch: {
    value: function(value) {
      $(this.$el)
        .val(value)
        .trigger("change");
    },
    options: function(options) {
      $(this.$el)
        .empty()
        .select2({
          data: options,
          width: "100%",
          placeholder: this.placeholder
        });
    }
  },
  destroyed() {
    $(this.$el)
      .off()
      .select2("destroy");
  }
};
</script>