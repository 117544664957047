<template>
  <div class="row mt-5">
    <div class="col mb-2">
      <div class="d-flex justify-content-between">
        <h4 class="font-weight-bold"></h4>

        <div
          v-if="!isView"
          class="btn-group"
          role="group"
          aria-label="Basic example"
        >
          <button
            class="btn btn-success"
            type="button"
            @click="addOrderDetails"
          >
            <i class="icon icon-left mdi mdi-plus text-white"></i>
            Add Product
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th scope="col">Item #</th>
            <th scope="col">Description</th>
            <th scope="col">Quantity</th>
            <th scope="col">Total</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.quantity }}</td>
            <td>&#8358;{{ formatMoney(item.quantity * item.price) }}</td>
            <td>
              <button
                v-if="!isView"
                class="btn btn-success btn-sm btn-rounded btn-space mr-1"
                @click.prevent="onEdit(item)"
              >
                <i class="icon mdi mdi-edit text-white"></i>
                Edit
              </button>
              <RemoveItem
                v-if="!isView"
                :item="item"
                @onRemove="onRemoveHandler"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { helper } from "@/helper";
import RemoveItem from "../../components/SettingsPurchaseOrder/ItemDetails/RemoveItemDetails";

export default {
  name: "ManufacturerPurchaseOrderDetail",

  props: ["items", "isView"],

  components: {
    RemoveItem,
  },

  methods: {
    addOrderDetails() {
      // eslint-disable-next-line no-undef
      $("#mod-product-add").modal("show");
    },

    addItem() {
      // eslint-disable-next-line no-undef
      $("#mod-apply-order-item").modal("show");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    onEdit(item) {
      this.$emit("performEdit", {
        id: item.productId,
        productId: item.id,
        quantity: item.quantity,
      });
    },

    onRemoveHandler(itemId) {
      this.$emit("onRemoveHandler", itemId);
    },

    removeItem(id) {
      this.$emit("onDeleteItem", id);
    },
  },
};
</script>

<style>
</style>