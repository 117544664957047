<template>
  <div class="row">
    <div class="col-lg-12">
			<div class="tabs-section">
				<div class="tab">
					<button class="tablinks" @click.prevent="openTab($event, 'Sent')" id="defaultOpen">Sent Messages</button>
					<button class="tablinks" @click.prevent="openTab($event, 'Scheduled')">Scheduled Messages</button>
				</div>

        <div id="Sent" class="tabcontent">
         <div
						:class="[
							'card',
							'card-border-color',
							'card-border-color-primary',
							' card-table',
							'be-loading',
							{ 'be-loading-active': isBusy },
						]"
					>
						<div class="card-header">
              <div>
                <button class="btn btn-success btn-rounded btn-space text-white" @click.prevent="openFilter">
                  <i class="feather icon-filter text-white"></i> Filter
                </button>
              </div>
              <div v-if="hasPermission('Broadcast_Message_Create')">
                <button class="btn btn-rounded btn-primary btn-space text-white" @click="createNewBrod">
                  Create New Broadcast
                </button>
              </div>
						</div>
						<div class="card-body">
              <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer table-striped table-hover-animation">
                <div class="row be-datatable-body">
                  <div class="col-sm-12 table-responsive" style="min-height: 350px;">
                    <table
                      class="table table-fw-widget dataTable no-footer text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th>Message Title</th>
                          <th>Sender</th>
                          <th>Broadcast Date</th>
                          <th>Recipient</th>
                          <th>Open Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="m in sentMessages" :key="m.id" @click.prevent="openBroadcastPreview(m)" style="cursor: pointer">
                          <td>
                            {{m.title}}
                          </td>
                          <td>
                            {{m.sender}}
                          </td>
                          <td>
                            {{formatDate(m.broadCastTime)}}
                          </td>
                          <td>
                            {{m.recipents}}
                          </td>
                          <td>
                             {{m.openRate}}
                          </td>
                        </tr>
                        <tr v-if="!sentMessages.length">
                          <td colspan="10">
                            <div class="text-center" style="padding-top: 50px">
                              <span
                                style="font-size: 4.615rem"
                                class="mdi mdi-basket"
                              ></span>
                            </div>
                            <h3
                              class="text-center"
                              style="padding-bottom: 100px"
                            >
                              You have not sent any message.
                            </h3>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <!-- <Pagination
                          :limit="pageSize"
                          :count="pageCount"
                          :page="page"
                          :itemsCount="count"
                          @changePage="gotoPage"
                          @changeLimit="changeLimit"
                    /> -->
                  </div>
                </div>
              </div>

              <!-- <div v-if="sentMessages.length > 0">
                <div v-for="m in sentMessages" :key="m.id" class="mb-2" style="border-bottom: 0.5px solid gray">
                  <h4 class="text-primary">{{m.title}}</h4>
                  <p> {{m.message}}</p>
                </div>
              </div>
            
              <div v-else class="w-100 text-center">
                <p>You have no message</p>
              </div> -->

            </div>
            <div class="card-footer">
              <Pagination
                :limit="pageSize"
                :count="pageCount"
                :page="page"
                :itemsCount="count"
                @changePage="gotoPage"
                @changeLimit="changeLimit"
              />
            </div>
          </div>
        </div>

         <div id="Scheduled" class="tabcontent">
               <div
                :class="[
                  'card',
                  'card-border-color',
                  'card-border-color-primary',
                  ' card-table',
                  'be-loading',
                  { 'be-loading-active': isBusy },
                ]"
              >
                <div class="card-header">
                  <div>
                    <button class="btn btn-success btn-rounded btn-space text-white" @click="openScheduledFilter">
                      <i class="feather icon-filter text-white"></i> Filter
                    </button>
                  </div>
                  <div v-if="hasPermission('Broadcast_Message_Create')">
                    <button class="btn btn-rounded btn-primary btn-space text-white" @click="createNewBrod">
                      Create New Broadcast
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer table-striped table-hover-animation">
                    <div class="row be-datatable-body">
                      <div class="col-sm-12 table-responsive" style="min-height: 350px;">
                        <table
                          class="table table-fw-widget dataTable no-footer text-nowrap"
                        >
                          <thead>
                            <tr>
                              <th>Message Title</th>
                              <th>Sender</th>
                              <th>Broadcast Date</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="m in scheduledMessages" :key="m.id" >
                              <td>
                                {{m.title}}
                              </td>
                              <td>
                                {{m.sender}}
                              </td>
                              <td>
                                {{formatDate(m.broadCastTime)}}
                              </td>
                              <td>
                                <button
                                  class="btn btn-success btn-sm dropdown-toggle"
                                  type="button"
                                  id="dropdownMenu2"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  Actions
                                </button>
                                  <div
                                    class="dropdown-menu px-1"
                                    aria-labelledby="dropdownMenu2"
                                  >
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      @click.prevent="openBroadcastPreview(m)"
                                    >
                                      View
                                    </a>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      v-if="hasPermission('Broadcast_Message_Update')"
                                      @click.prevent="editNewBrod(m.id)"
                                    >
                                      Edit
                                    </a>
                                    <a
                                      class="dropdown-item text-danger"
                                      href="#"
                                      v-if="hasPermission('Broadcast_Message_Delete')"
                                      @click.prevent="deleteScheduledMessage(m.id)"
                                    >
                                      Delete
                                    </a>
                                  </div>
                              </td>
                            </tr>
                            <tr v-if="!scheduledMessages.length">
                              <td colspan="10">
                                <div class="text-center" style="padding-top: 50px">
                                  <span
                                    style="font-size: 4.615rem"
                                    class="mdi mdi-basket"
                                  ></span>
                                </div>
                                <h3
                                  class="text-center"
                                  style="padding-bottom: 100px"
                                >
                                  You have not sent any message.
                                </h3>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="card-footer">
                  <Pagination
                    :limit="pageSizeSch"
                    :count="pageCountSch"
                    :page="pageSch"
                    :itemsCount="countSch"
                    @changePage="gotoPageSch"
                    @changeLimit="changeLimitSch"
                  />
                </div>
              </div>
        </div>

      </div>
    </div>

    <BroadcastPreview @closeModal="closeBroadcastPreview" :details="previewDetails"></BroadcastPreview>

     <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
                <div>
                    <h4 class="modal-title"><b>Filter</b></h4>
                </div>
                <button
                    class="close md-close"
                    type="button"
                    data-dismiss="modal"
                    aria-hidden="true"
                >
                    <span class="mdi mdi-close"></span>
                </button>
            </div>
            <div class="modal-body">
              <div class="form-group row">
                <label class="col-12 col-sm-4">Sender: </label>
                <div class="col-12 col-sm-8 ">
                  <input
                    :class="['form-control']"
                    type="text"
                    v-model="filter.sender"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-12 col-sm-4">Start Date: </label>
                <div class="col-12 col-sm-8 ">
                  <input
                      :class="['form-control']"
                      type="date"
                      placeholder="Select a date"
                      v-model="filter.startDate"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-12 col-sm-4">End Date: </label>
                <div class="col-12 col-sm-8 ">
                  <input
                      :class="['form-control']"
                      type="date"
                      placeholder="Select a date"
                      v-model="filter.endDate"
                  />
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                style="width: 100px;"
                class="btn btn-text btn-space"
                type="button"
                data-dismiss="modal"
                @click="resetFilter"
              >
                <span class="text-danger"> Reset </span>
              </button>

               <button
                style="width: 100px;"
                class="btn btn-primary"
                type="button"
                data-dismiss="modal"
                :disabled="isBusy"
                @click="fetchSentMessages"
              >
                <span v-if="!isBusy">Filter</span>
                <span v-else>Busy...</span>
              </button>

            </div>
          </div>
        </div>
    </div>

     <div class="modal fade colored-header colored-header-primary" id="mod-scheduled-filter" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
                <div>
                    <h4 class="modal-title"><b>Filter</b></h4>
                </div>
                <button
                    class="close md-close"
                    type="button"
                    data-dismiss="modal"
                    aria-hidden="true"
                >
                    <span class="mdi mdi-close"></span>
                </button>
            </div>
            <div class="modal-body">
              <div class="form-group row">
                <label class="col-12 col-sm-4">Sender: </label>
                <div class="col-12 col-sm-8 ">
                  <input
                    :class="['form-control']"
                    type="text"
                    v-model="scheduledFilter.sender"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-12 col-sm-4">Start Date: </label>
                <div class="col-12 col-sm-8 ">
                  <input
                      :class="['form-control']"
                      type="date"
                      placeholder="Select a date"
                      v-model="scheduledFilter.startDate"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-12 col-sm-4">End Date: </label>
                <div class="col-12 col-sm-8 ">
                  <input
                      :class="['form-control']"
                      type="date"
                      placeholder="Select a date"
                      v-model="scheduledFilter.endDate"
                  />
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                style="width: 100px;"
                class="btn btn-text btn-space"
                type="button"
                data-dismiss="modal"
                @click="resetScheduledFilter"
              >
                <span class="text-danger"> Reset </span>
              </button>

               <button
                style="width: 100px;"
                class="btn btn-primary"
                type="button"
                data-dismiss="modal"
                :disabled="isBusy"
                @click="fetchScheduledMessages"
              >
                <span v-if="!isBusy">Filter</span>
                <span v-else>Busy...</span>
              </button>

            </div>
          </div>
        </div>
    </div>

  </div>
</template>


<script>
/* eslint-disable */
import moment from "moment";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";
import Pagination from "../../components/Pagination.vue";
import BroadcastPreview from "../../components/Broadcast/BroadcastPreview.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "Enterprise-Broadcast",
  components: {
    Pagination,
    BroadcastPreview
  },

  mounted: function() {
      this.openDefaultTab();
      this.fetchSentMessages();
      this.fetchScheduledMessages();
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      sentMessages: [],
      scheduledMessages: [],

      previewDetails: {
        title: "",
        sender: "",
        date: "",
        message: "",
        imagePosition: "",
        infoUrl: "",
        imageAttach: "",
      },

      filter: {
        sender: "",
        startDate: "",
        endDate: ""
      },

      scheduledFilter: {
        sender: "",
        startDate: "",
        endDate: ""
      },

      count: 0,
      page: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,

      countSch: 0,
      pageSch: 1,
      pageSizeSch: 10,
      pageCountSch: 0,
      hasNextSch: false,
      hasPrevSch: false,
    }
  },

  methods: {
    openDefaultTab(){
      document.getElementById("defaultOpen").click();
    },
    openTab(evt, cityName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(cityName).style.display = "block";
      evt.currentTarget.className += " active";
    },

    formatDate(date) {
				return moment(date).format("ll");
    },

    openBroadcastPreview(m){
        this.previewDetails = {
            title: m.title,
            message: m.message,
            sender: m.sender,
            date: m.broadCastTime ? moment(m.broadCastTime).format("dddd, MMM DD, hh:mma"): moment(new Date()).format("dddd, MMM DD, hh:mma"),
            imagePosition: m.imagePosition,
            infoUrl: m.link,
            imageAttach: m.imageUrl,
        }
        $("#mod-read-broadcast").modal("show");
    },

    closeBroadcastPreview(){
        $("#mod-read-broadcast").modal("hide");
         this.previewDetails = {
            title: "",
            sender: "",
            date: "",
            message: "",
            imagePosition: "",
            infoUrl: "",
            imageAttach: "",
         };
    },

    openFilter(){
      $("#mod-filter").modal("show");
    },

    closeFilter(){
      $("#mod-filter").modal("hide");
    },

    resetFilter(){
      this.filter = {
        sender: "",
        startDate: "",
        endDate: ""
      }
      this.fetchSentMessages();
    },

    openScheduledFilter(){
      $("#mod-scheduled-filter").modal("show");
    },

    closeScheduledFilter(){
      $("#mod-scheduled-filter").modal("hide");
    },

    resetScheduledFilter(){
      this.scheduledFilter = {
        sender: "",
        startDate: "",
        endDate: ""
      }
      this.fetchScheduledMessages();
    },

    deleteScheduledMessage(id){
      this.isBusyDeleting = true;

      this.$http
          .delete(`/BroadcastMessages/${id}`)
          .then(function (res) {
            this.isBusyDeleting = false;
            this.$toast.success(res.body, {
              icon: true,
              rtl: false,
            });
            this.fetchScheduledMessages();
          })
          .catch(function () {
            this.isBusyDeleting = false;
          });
    },

    fetchSentMessages(){
      this.isBusy = true;

      this.$http
          .get(`/BroadcastMessages?Status=1&PageSize=${this.pageSize}&PageNumber=${this.page}&Sender=${this.filter.sender}&StartDate=${this.filter.startDate}&EndDate=${this.filter.endDate}`)
          .then(function (res) {
            this.closeFilter();
            this.sentMessages = res.body.data;
            this.page = res.body.pageNumber;
            this.pageSize = res.body.pageSize;
            this.count = res.body.count;
            this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
            this.hasPrev = res.body.hasPrev;
            this.hasNext = res.body.hasNext;
            this.isBusy = false;
          })
          .catch(function () {
            this.isBusy = false;
          });
    },

    fetchScheduledMessages(){
      this.isBusy = true;

      this.$http
          .get(`/BroadcastMessages?Status=2&PageSize=${this.pageSizeSch}&PageNumber=${this.pageSch}&Sender=${this.scheduledFilter.sender}&StartDate=${this.scheduledFilter.startDate}&EndDate=${this.scheduledFilter.endDate}`)
          .then(function (res) {
            this.closeScheduledFilter();
            this.scheduledMessages = res.body.data;
            this.pageSch = res.body.pageNumber;
            this.pageSizeSch = res.body.pageSize;
            this.countSch = res.body.count;
            this.pageCountSch = Math.ceil(res.body.count / res.body.pageSize);
            this.hasPrevSch = res.body.hasPrev;
            this.hasNextSch = res.body.hasNext;
            this.isBusy = false;
          })
          .catch(function () {
            this.isBusy = false;
          });
    },

    createNewBrod(){
      this.$router.push('/manufacturer-broadcast-new');
    },

    editNewBrod(id){
      if(id)
      this.$router.push(`/manufacturer-broadcast-new/${id}`);
    },

    prev() {
      this.page -= 1;
      this.fetchSentMessages();
    },
    next() {
      this.page += 1;
      this.fetchSentMessages();
    },

    gotoPage(page) {
      this.page = page;
      this.fetchSentMessages();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.fetchSentMessages();
    },

    prevSch() {
      this.pageSch -= 1;
      this.fetchScheduledMessages();
    },
    nextSch() {
      this.pageSch += 1;
      this.fetchScheduledMessages();
    },

    gotoPageSch(page) {
      this.pageSch = page;
      this.fetchScheduledMessages();
    },

    changeLimitSch(limit) {
      this.pageSizeSch = limit;
      this.fetchScheduledMessages();
    },
  }
};
</script>


<style scoped>
  /* Style the tab */
	.tab {
	overflow: hidden;
	display: flex;
	/* border: 1px solid #ccc; */
	/* background-color: #fff; */
	padding: 15px;
	}

	/* Style the buttons inside the tab */
	.tab button {
	width: 50%;
	background-color: inherit;
	text-align: center;
	float: left;
	border: none;
	border-bottom: 2px solid #ccc;
	outline: none;
	cursor: pointer;
	padding: 14px 16px;
	transition: 0.3s;
	font-size: 17px;
	}

	/* Change background color of buttons on hover */
	.tab button:hover {
	/* background-color: #766cea; */
	color: #766cea !important;
	}

	/* Create an active/current tablink class */
	.tab button.active {
	border-bottom: 2px solid #766cea;
		color: #766cea !important;
	}

	/* Style the tab content */
	.tabcontent {
	display: none;
	padding: 6px 12px;
	border-top: none;
	}
</style>
