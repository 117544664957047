<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
						<i class="feather icon-filter text-white"></i> Filter
					</button>
					<div>
						<button
							class="btn btn-primary btn-rounded btn-space"
							@click="viewAdd"
							v-if="hasPermission('Product_Create')"
						>
							<span class="icon icon-left mdi mdi-plus text-white"></span> New product
						</button>

						<div
							class="dropdown btn btn-default ml-1"
							v-if="hasPermission('Product_Bulk_Upload')"
						>
							<button
								class="btn btn-primary btn-rounded btn-space float-right dropdown-toggle"
								type="button"
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<span class="icon icon-left mdi mdi-plus text-white"></span>
								Bulk Upload
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<button
									class="dropdown-item btn btn-text w-100"
									@click="viewUploadProducts"
								>
									Product
								</button>
								<button
									class="dropdown-item btn btn-text w-100"
									@click="viewUploadPrice"
								>
									Price
								</button>
							</div>
						</div>
						
						<button v-if="hasPermission('Product_Export')" class="btn btn-info btn-rounded btn-space" @click.prevent="openConfirmDownload">
							<span>
								<i class="icon icon-left mdi mdi-arrow-top-right" />
								Export Data
							</span>
						</button>
					</div>
				</div>
				<div class="card-body">
					<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table
									class="table table-fw-widget dataTable no-footer table-striped table-hover-animation"
								>
									<thead>
										<tr>
											<th>Name</th>
											<th>Status</th>
											<th>Category</th>
											<th>Created</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="p in Products" :key="p.id">
											<td>
												<div class="d-flex align-items-center">
													<img
														v-if="p.imageUrl"
														class="image-preview mr-1"
														:src="p.imageUrl"
														alt="logo"
														width="40"
														height="40"
													/>
													<img
														v-else
														class="image-preview mr-1"
														src="/assets/img/avatar.svg"
														alt="logo"
														width="40"
													/>
													<div>
														{{ p.name }}
														<br />
														<b>
															{{ p.code }}
															/
															{{ p.unit }}
														</b>
													</div>
												</div>
											</td>
											<td>
												<span
													:class="[
														'badge',
														' badge-pill',
														{ 'badge-success': p.active },
														{ 'badge-danger': !p.active },
													]"
												>
													{{ p.active ? "Active" : "Inactive" }}
												</span>
											</td>
											<td>{{ p.category.name }}</td>
											<td>{{ formatDate(p.created) }}</td>
											<td>
												<div
													class="text-right d-flex"
													v-if="
														hasPermission('Product_Update') ||
															hasPermission('Price_Option_View')
													"
												>
													<router-link
														class="btn btn-outline-primary btn-sm btn-rounded btn-space mr-1"
														v-if="
															p.parentId == user.parentId &&
																hasPermission('Price_Option_View')
														"
														:to="{
															path: `manufacturer-products/${p.id}/manage-price-option`,
														}"
													>
														<span>{{
															user.isSalesUser
																? "View Price Options"
																: "Manage Price Option"
														}}</span>
													</router-link>
													<button
														v-if="
															p.parentId == user.parentId &&
																hasPermission('Product_Update')
														"
														class="btn btn-success btn-sm btn-rounded btn-space"
														@click.prevent="viewEdit(p)"
													>
														<i class="feather icon-edit"></i> &nbsp;Edit
													</button>
												</div>
											</td>
										</tr>
										<tr v-if="!Products.length">
											<td colspan="6">
												<div class="text-center" style="padding-top: 50px">
													<span
														style="font-size: 4.615rem"
														class="mdi mdi-tag-multiple"
													></span>
												</div>
												<h3
													class="text-center"
													style="padding-bottom: 100px"
												>
													You don't have any products.
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
								<Pagination
									:limit="pageSize"
									:count="pageCount"
									:page="page"
									:itemsCount="count"
									@changePage="gotoPage"
									@changeLimit="changeLimit"
								/>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ count }} product{{ count != 1 ? "s" : "" }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>
		<div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
			<div class="modal-dialog lg-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Create new productss</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body modal-overflow">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Category</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="createProduct.categoryId"
								>
									<option
										v-for="cat in filteredCategory"
										:key="cat.id"
										:value="cat.id"
									>
										{{ cat.name }}
									</option>
								</select>
								<ul v-if="v1.categoryId" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12 col-sm-10 col-lg-12">
									<input
										:class="['form-control', { 'is-invalid': v1.code }]"
										class="col-12"
										type="text"
										v-model="createProduct.code"
										@blur="validateProductCode(createProduct.code)"
									/>
									<ul v-if="v1.code" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
									<span
										v-if="isProductValid == true"
										class="text-danger error-text "
									>
										Product code already exists
									</span>
								</div>
								<i
									v-if="isProductValid == true"
									class="text-danger fa fa-times"
								></i>
								<i
									v-if="isProductValid == false"
									class="text-success fa fa-check"
								></i>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.name }]"
									type="text"
									v-model="createProduct.name"
								/>
								<ul v-if="v1.name" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Description</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									class="form-control"
									v-model="createProduct.description"
								></textarea>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Unit</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="createProduct.unit"
								>
									<option
										v-for="unit in units"
										:key="unit.name"
										:value="unit.name"
									>
										{{ unit.name }}
									</option>
								</select>
								<ul v-if="v1.unit" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Default Retail Price (RRP)</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.retailPrice }]"
									type="number"
									v-model="createProduct.retailPrice"
								/>
								<ul v-if="v1.retailPrice" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Default Distributor Price (DP)</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.distributorPrice }]"
									type="number"
									v-model="createProduct.distributorPrice"
								/>
								<ul v-if="v1.distributorPrice" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Active</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="createProduct.active"
									/>
									<span class="custom-control-label">This product is active</span>
								</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Available for sale</label
							>
							<div class="col-12 col-sm-8 col-lg-8 d-flex align-items-center ">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="createProduct.availability"
										false-value="0"
										true-value="1"
									/>
									<span class="custom-control-label"
										>This product is available for sale</span
									>
								</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Allow Price Override</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="createProduct.allowPriceOverride"
									/>
									<span class="custom-control-label"
										>Price can be overriden by distributor</span
									>
								</label>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product Image</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<div class="d-flex align-items-center">
										<div>
											<img
												v-if="previewUrl"
												class="image-preview"
												:src="previewUrl"
												alt="logo"
												width="40"
												height="40"
											/>
										</div>

										<input
											id="file"
											type="file"
											class="custom-file-input"
											@change="uploadFile"
											ref="file"
											hidden
										/>
										<button
											v-if="previewUrl"
											class="btn btn-custom btn-outline-danger btn-nobordered btn-md ml-2"
											@click="previewUrl = ''"
										>
											Delete image
										</button>
										<button
											v-else
											class="btn btn-custom btn-outline-primary btn-md ml-2"
											@click="onPickFile"
										>
											Upload Image
										</button>
									</div>

									<small class="image-size-text">(Max 1MB)</small>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click="previewUrl = ''"
						>
							Cancel
						</button>
						<button
							style="width: 160px"
							:disabled="isBusyModal || isProductValid == true"
							class="btn btn-primary"
							type="button"
							@click.prevent="create"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Add product</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Edit product</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
							@click="cancel"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Category</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="updateProduct.categoryId"
								>
									<option
										v-for="cat in filteredCategory"
										:key="cat.id"
										:value="cat.id"
									>
										{{ cat.name }}
									</option>
								</select>
								<ul v-if="v2.categoryId" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.code }]"
									type="text"
									v-model="updateProduct.code"
								/>
								<ul v-if="v2.code" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.name }]"
									type="text"
									v-model="updateProduct.name"
								/>
								<ul v-if="v2.name" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Description</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									class="form-control"
									v-model="updateProduct.description"
								></textarea>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Unit</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="updateProduct.unit"
								>
									<option
										v-for="unit in units"
										:key="unit.name"
										:value="unit.name"
									>
										{{ unit.name }}
									</option>
								</select>
								<ul v-if="v2.unit" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Default Retail Price (RRP)</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.retailPrice }]"
									type="number"
									v-model="updateProduct.retailPrice"
								/>
								<ul v-if="v2.retailPrice" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Default Distributor Price (DP)</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.distributorPrice }]"
									type="number"
									v-model="updateProduct.distributorPrice"
								/>
								<ul v-if="v2.distributorPrice" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Active</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="updateProduct.active"
									/>
									<span class="custom-control-label">This product is active</span>
								</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Allow Price Override</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="updateProduct.allowPriceOverride"
									/>
									<span class="custom-control-label"
										>Price can be overriden by distributor</span
									>
								</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Available for sale</label
							>
							<div class="col-12 col-sm-8 col-lg-8 d-flex align-items-center ">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="updateProduct.availability"
									/>
									<span class="custom-control-label"
										>This product is available for sale</span
									>
								</label>
								<ul v-if="v2.availability" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product Image</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<div class="d-flex align-items-center">
										<div>
											<img
												v-if="previewUrl"
												class="image-preview"
												:src="previewUrl"
												alt="logo"
												width="40"
												height="40"
											/>
										</div>

										<input
											id="file"
											type="file"
											class="custom-file-input"
											@change="uploadFile"
											ref="file"
											hidden
										/>
										<button
											v-if="previewUrl"
											class="btn btn-custom btn-outline-danger btn-nobordered btn-md ml-2"
											@click="cancelUpload"
										>
											Delete image
										</button>
										<button
											v-else
											class="btn btn-custom btn-outline-primary btn-md ml-2"
											@click="onPickFile"
										>
											Upload Image
										</button>
									</div>

									<small class="image-size-text">(Max 1MB)</small>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click="previewUrl = ''"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="update"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Update</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Filter</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Status</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="select2 form-control"
									placeholder="Select an option"
									v-model="filter.status"
								>
									<option value="0">All Products</option>
									<option value="True">Active</option>
									<option value="False">Disabled</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Category</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="filter.categoryId"
								>
									<option value="0">All Categories</option>
									<option v-for="cat in categories" :key="cat.id" :value="cat.id">
										{{ cat.name }}
									</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Other filters</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Filter by code or name..."
									v-model="filter.qry"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							@click="reset"
						>
							Reset
						</button>
						<button
							style="width: 100px"
							class="btn btn-primary md-close"
							type="button"
							@click="getProducts"
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-download" role="dialog">
			<div class="modal-dialog sm-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Export Data</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body p-2">
						<p>Are you sure you want to export data?</p>
					</div>
					<div class="modal-footer">
						<button class="btn btn-space btn-danger" data-dismiss="modal" aria-hidden="true">Cancel</button>
						<JsonExcel
							class="btn btn-default"
							:fields="Product_fields"
							name="product.csv"
							:fetch="downloadFile"
							type="csv"
							v-if="hasPermission('Product_Export')"
						>
							<button class="btn btn-info btn-rounded btn-space">
								<span>
									Proceed
								</span>
							</button>
						</JsonExcel>
					</div>
				</div>
			</div>
		</div>


		<Modal
			@proceed="finishDownload($event)"
			:isBusy="modal.isLoading"
			:text="modal.actionText"
			:message="modal.message"
		/>
	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import { helper } from "@/helper";
	import Select2 from "@/components/Select2";
	import SystemPermissions from "@/permissions";
	import Modal from "@/components/helpers/modal.vue";
	import JsonExcel from "vue-json-excel";
	import Pagination from "../components/Pagination.vue";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "settingsProducts",
		components: {
			Pagination,
			Modal,
			JsonExcel,
			Select2,
		},

		created() {
			if (!this.hasPermission("Product_View")) {
				this.$router.push("/restricted");
			}
			this.getProducts();
			this.getTypes();
			this.getCategories();
			this.getUnits();
		},

		updated() {
			if (this.$route.params.id && this.Products) {
				const product = this.Products.find(prod => prod.id === this.$route.params.id);
				if (product) {
					this.viewEdit(product);
				}
			}
		},

		watch: {
			"createProduct.type": function(newType, oldType) {
				if (newType == "Infinite") {
					this.createProduct.quantity = 0;
				}
			},
			"updateProduct.type": function(newType, oldType) {
				if (newType == "Infinite") {
					this.updateProduct.quantity = 0;
				}
			},
		},

		data() {
			return {
				exportAllLoading: false,
				filter: {
					status: "True",
					categoryId: "0",
					qry: "",
				},
				isBusy: false,
				isBusyModal: false,
				createProduct: {
					code: null,
					name: null,
					description: null,
					unit: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					distributorPrice: null,
					availability: null,
					active: null,
					categoryId: null,
					restockLevel: 0,
					allowPriceOverride: null,
				},
				updateProduct: {
					code: null,
					name: null,
					description: null,
					unit: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					distributorPrice: null,
					active: true,
					categoryId: null,
					id: null,
					restockLevel: 0,
					allowPriceOverride: null,
					availability: null,
				},
				editProduct: {
					code: null,
					name: null,
					description: null,
					unit: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					distributorPrice: null,

					active: true,
					categoryId: null,
					id: null,
					restockLevel: 0,
					allowPriceOverride: null,
					availability: null,
				},
				v1: {
					code: false,
					name: false,
					unit: false,
					group: false,
					groupSize: false,
					quantity: false,
					type: false,
					retailPrice: false,
					distributorPrice: false,
					categoryId: false,
					restockLevel: false,
					availability: false,
				},
				v2: {
					code: false,
					name: false,
					unit: false,
					group: false,
					groupSize: false,
					quantity: false,
					type: false,
					retailPrice: false,
					distributorPrice: false,
					categoryId: false,
					restockLevel: false,
					availability: false,
				},
				isProductValid: null,
				Products: [],
				Product_fields: {
					"Product Code": "code",
					"Product Name": "name",
					"Product Category": "category",
					"Product Unit": "unit",
					"Product Creation Date": "creationDate",
					"Product Description": "description",
					"Product Last Modified Date": "modifiedDate",
					"Price Option ID": "priceOptionId",
					"Price Option Name": "priceOptionName",
					"Price Option Description": "priceOptionDescription",
					"Distributor Price": "distributorPrice",
					"Retail Price": "retailPrice",
					"Price Option Creation Date": "priceOptionCreationDate",
					"Price Option Last Modified Date": "priceOptionLastModifiedDate",
				},
				types: [],
				categories: [],
				units: [],
				count: 0,
				page: 0,
				pageNumber: 0,
				pageSize: 10,
				pageCount: 0,
				hasNext: false,
				hasPrev: false,
				modal: {
					isLoading: false,
					actionMethod: "",
					actionText: "",
					message: "",
				},
				images: null,
				previewUrl: "",
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},

			filteredCategory: function() {
				return this.categories.filter(x => x.active);
			},
		},
		// mounted() {
		// 	this.openCreateModal();
		// },

		methods: {
			openCreateModal() {
				$("#mod-add").modal("show");
			},
			onPickFile() {
				this.$refs.file.click();
			},
			cancelUpload() {
				this.previewUrl = "";
				this.images = null;
			},
			uploadFile() {
				this.images = this.$refs.file.files[0];
				this.previewUrl = URL.createObjectURL(this.images);
			},
			openModal(btnText, message) {
				this.modal.actionText = btnText;
				this.modal.message = message;
				$("#modal").modal("show");
			},

			closeModal() {
				this.modal.actionText = "";
				this.modal.message = "";
				$("#modal").modal("hide");
			},

			async validateProductCode(code) {
				this.isBusy = true;
				try {
					const response = await this.$http.get(`/Products/isProductCodeExist/${code}`);
					if (response.ok) {
						this.isBusy = false;
						this.isProductValid = response.data;
						return;
					}
				} catch (err) {
					this.$toast.info(err, {
						icon: true,
						rtl: false,
					});
				}
			},

			async downloadFile() {
				await this.$http
					.get("/Products/getAllProducts")
					.then(function(res) {
						this.products = res.body;
					})
					.catch(function() {});
				$("#mod-download").modal("hide");
				return this.products;
			},

			openConfirmDownload(){
				$("#mod-download").modal("show");
			},

			reset() {
				this.filter = {
					status: "True",
					categoryId: "0",
					qry: "",
				};

				this.getProducts();
			},

			cancel() {
				const currentProduct = this.Products.find(p => p.id === this.editProduct.id);
				currentProduct.name = this.editProduct.name;
				currentProduct.code = this.editProduct.code;
				currentProduct.retailPrice = this.editProduct.retailPrice;
				currentProduct.distributorPrice = this.editProduct.distributorPrice;
				currentProduct.unit = this.editProduct.unit;
				currentProduct.active = this.editProduct.active;
				currentProduct.categoryId = this.editProduct.categoryId;
				currentProduct.allowPriceOverride = this.editProduct.allowPriceOverride;
				currentProduct.quantity = this.editProduct.quantity;
				$("#mod-edit").modal("hide");
			},

			viewExport() {
				//   $("#mod-export").modal("show");
			},

			viewAdd() {
				this.createProduct = {
					code: null,
					name: null,
					description: null,
					unit: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					distributorPrice: null,
					active: false,
					categoryId: null,
					restockLevel: 0,
					allowPriceOverride: false,
				};
				this.v1 = {
					code: false,
					name: false,
					unit: false,
					group: false,
					groupSize: false,
					quantity: false,
					type: false,
					categoryId: false,
					restockLevel: 0,
				};
				$("#mod-add").modal("show");
			},

			viewEdit(c) {
				var editValue = {
					code: c.code,
					name: c.name,
					description: c.description,
					quantity: c.quantity,
					type: c.type,
					retailPrice: c.retailPrice,
					distributorPrice: c.distributorPrice,
					unit: c.unit,
					categoryId: c.category.id,
					active: c.active,
					restockLevel: c.restockLevel,
					allowPriceOverride: c.allowPriceOverride,
					id: c.id,
					availability: c.saleStatus,
				};
				this.editProduct = editValue;
				this.previewUrl = c.imageUrl;
				this.updateProduct = c;
				this.updateProduct.availability = c.saleStatus;
				$("#mod-edit").modal("show");
			},

			viewFilter() {
				this.page = 0;
				$("#mod-filter").modal("show");
			},

			async create() {
				this.isBusyModal = true;
				this.isBusy = true;
				const formData = new FormData();
				formData.append("code", this.createProduct.code);
				formData.append("name", this.createProduct.name);
				formData.append("description", this.createProduct.description);
				formData.append("unit", this.createProduct.unit);
				formData.append("volume", "N/A");
				formData.append("group", "N/A");
				formData.append("groupSize", 0);
				formData.append("quantity", 9999999);
				formData.append("type", "Finite");
				formData.append("categoryId", this.createProduct.categoryId);
				formData.append("distributorPrice", this.createProduct.distributorPrice);
				formData.append("retailPrice", this.createProduct.retailPrice);
				formData.append("active", this.createProduct.active);
				formData.append("parentId", this.user.parentId);
				formData.append("restockLevel", this.createProduct.restockLevel);
				formData.append("SaleStatus", this.createProduct.availability);
				formData.append("allowPriceOverride", this.createProduct.allowPriceOverride);
				formData.append("image", this.images);

				if (this.validateCreate()) {
					await this.$http
						.post("/Products/createForManufacturer", formData)
						.then(function(res) {
							this.isBusyModal = false;
							this.$toast.success("Product created successfully.", {
								icon: true,
								rtl: false,
							});
							this.isBusy = false;
							(this.previewUrl = ""), (this.isBusyModal = false);
							$("#mod-add").modal("hide");
							this.getProducts();
						})
						.catch(function() {
							this.isBusy = false;
							this.isBusyModal = false;
							(this.previewUrl = ""), $("#mod-add").modal("hide");
						});
				}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			validateCreate() {
				this.v1 = {
					code: false,
					name: false,
					unit: false,
					quantity: false,
					type: false,
					categoryId: false,
					restockLevel: false,
				};
				var isValid = true;

				if (!this.createProduct.code) {
					this.v1.code = true;
					isValid = false;
				}

				if (!this.createProduct.name) {
					this.v1.name = true;
					isValid = false;
				}

				if (!this.createProduct.unit) {
					this.v1.unit = true;
					isValid = false;
				}

				if (!this.createProduct.categoryId) {
					this.v1.categoryId = true;
					isValid = false;
				}

				if (Number(this.createProduct.retailPrice) <= 0) {
					this.v1.retailPrice = true;
					isValid = false;
				}

				if (Number(this.createProduct.distributorPrice) <= 0) {
					this.v1.distributorPrice = true;
					isValid = false;
				}

				if (Number(this.createProduct.restockLevel) < 0) {
					this.v1.restockLevel = true;
					isValid = false;
				}

				return isValid;
			},

			async update() {
				this.isBusyModal = true;
				this.isBusy = true;
				const formData = new FormData();
				formData.append("code", this.updateProduct.code);
				formData.append("name", this.updateProduct.name);
				formData.append("description", this.updateProduct.description);
				formData.append("unit", this.updateProduct.unit);
				formData.append("volume", this.updateProduct.volume);
				formData.append("group", this.updateProduct.group);
				formData.append("groupSize", this.updateProduct.group);
				formData.append("quantity", 9999999);
				formData.append("type", "Finite");
				formData.append("categoryId", this.updateProduct.categoryId);
				formData.append("distributorPrice", this.updateProduct.distributorPrice);
				formData.append("retailPrice", this.updateProduct.retailPrice);
				formData.append("active", this.updateProduct.active);
				formData.append("id", this.updateProduct.id);
				formData.append("restockLevel", this.updateProduct.restockLevel);
				formData.append("SaleStatus", this.updateProduct.availability);
				formData.append("allowPriceOverride", this.updateProduct.allowPriceOverride);
				formData.append("image", this.images);

				if (this.validateUpdate()) {
					await this.$http
						.put("/Products/updateForManufacturer/" + this.updateProduct.id, formData)
						.then(function(res) {
							this.isBusyModal = false;
							// if (this.$route.params.id) this.$router.push("/products");
							this.$toast.success("Update successful", {
								icon: false,
								rtl: false,
							});
							this.isBusy = false;
							this.isBusyModal = false;
							$("#mod-edit").modal("hide");
							(this.previewUrl = ""), this.getProducts();
						})
						.catch(function() {
							this.isBusy = false;
							this.isBusyModal = false;
							(this.previewUrl = ""), this.getProducts();
						});
				}
			},
			validateUpdate() {
				this.v2 = {
					code: false,
					name: false,
					unit: false,
					// group: false,
					// groupSize: false,
					quantity: false,
					type: false,
					categoryId: false,
					restockLevel: false,
				};
				var isValid = true;

				if (!this.updateProduct.code) {
					this.v2.code = true;
					isValid = false;
				}

				if (!this.updateProduct.name) {
					this.v2.name = true;
					isValid = false;
				}

				if (!this.updateProduct.unit) {
					this.v2.unit = true;
					isValid = false;
				}

				if (!this.updateProduct.categoryId) {
					this.v2.categoryId = true;
					isValid = false;
				}

				if (Number(this.updateProduct.retailPrice) <= 0) {
					this.v2.retailPrice = true;
					isValid = false;
				}

				if (Number(this.updateProduct.distributorPrice) <= 0) {
					this.v2.distributorPrice = true;
					isValid = false;
				}

				if (Number(this.updateProduct.restockLevel) < 0) {
					this.v2.restockLevel = true;
					isValid = false;
				}

				return isValid;
			},

			async getProducts() {
				this.isBusy = true;

				await this.$http
					.get(
						"/Products/getListForManufacturer?page=" +
							this.page +
							"&pageSize=" +
							this.pageSize +
							(this.filter.status != "0" ? "&active=" + this.filter.status : "") +
							(this.filter.categoryId != "0"
								? "&categoryId=" + this.filter.categoryId
								: "") +
							(this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
							(this.user.parentId ? "&parentId=" + this.user.parentId : ""),
					)
					.then(function(res) {
						this.Products = res.body.data;
						this.page = res.body.page;
						this.pageSize = res.body.pageSize;
						this.count = res.body.count;
						this.hasPrev = res.body.hasPrev;
						this.hasNext = res.body.hasNext;
						this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
						this.isBusy = false;
						this.previewUrl = "";
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			getTypes() {
				this.types = ["Finite", "Infinite"];
			},

			getCategories() {
				var t = "Product Category";

				this.$http
					.get(
						"/Categories/getListNoPagging?type=" +
							t +
							(this.user.parentId
								? "&parentId=" + this.user.parentId
								: "Product Category"),
					)
					.then(function(res) {
						this.categories = res.body;
					})
					.catch(function() {});
			},

			getUnits() {
				var t = "Product Unit";
				var a = true;

				this.$http
					.get(
						"/Categories/getListNoPagging?type=" +
							t +
							"&active=" +
							a +
							(this.user.parentId
								? "&parentId=" + this.user.parentId
								: "Product Category"),
					)
					.then(function(res) {
						this.units = res.body;
					})
					.catch(function() {});
			},

			formatDate(date) {
				return moment(date).format("lll");
			},

			formatMoney(val) {
				return helper.formatMoney(val);
			},

			formatNumber(val) {
				return helper.formatNumber(val);
			},

			prev() {
				this.page -= 1;
				this.getProducts();
			},
			next() {
				this.page += 1;
				this.getProducts();
			},

			gotoPage(page) {
				this.page = page - 1;
				this.getProducts();
			},

			changeLimit(limit) {
				this.pageSize = limit;
				this.getProducts();
			},
			viewUploadProducts() {
				this.$router.push("/manufacturer-products-upload");
			},

			viewUploadPrice() {
				this.$router.push("/manufacturer-price-upload");
			},
		},
	};
</script>
<style scoped>
	.modal-overflow {
		max-height: 500px;
		overflow-y: auto;
	}
	.image-size-text {
		color: Gray;
		font-size: 10px;
		font-weight: 200;
		height: auto !important;
	}
	.image-preview {
		border-radius: 50%;
	}
	.btn-custom {
		padding: 8px 18px !important;
	}
	.btn-nobordered {
		border: none;
	}
	.lg-modal {
		max-width: 600px !important;
		width: 100%;
	}
</style>
